import { DOCUMENT } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HeaderFooterService } from "@shared/ui";
import { CaseAnalysisService } from "@telespot/analysis-refactor/data-access";
import {
  IAnalyst,
  ReportGeneratorService,
} from "@telespot/reports/data-access";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { AuthService } from "@telespot/web-core";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Case } from "@telespot/sdk";

@Component({
  selector: "ts-printable-report",
  templateUrl: "./printable-report.component.html",
  styleUrls: ["./printable-report.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CaseAnalysisService, ReportGeneratorService],
})
export class PrintableReportComponent implements OnDestroy {
  public loading = false;
  public paddingValue = 16;
  public scale = 2;

  constructor(
    private caseAnalysisService: CaseAnalysisService,
    public reportService: ReportGeneratorService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private headerFooterService: HeaderFooterService,
    @Inject(DOCUMENT) private _document: Document,
    private _logger: LoggerService,
    private _authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    this.caseAnalysisService.setCase(this.route.snapshot.data["case"]);
  }

  ngOnDestroy() {
    // Make sure height is restored
    try {
      this.renderer.setStyle(
        this._document.getElementById("app-div"),
        "height",
        "100vh"
      );
    } catch (err) {
      //
    }
  }

  openPrintDialog(caseName: string) {
    const reportContainer = document.querySelector(
      ".pdf-report-container"
    ) as HTMLElement;

    if (!reportContainer) {
      console.error("Report container not found.");
      return;
    }

    const style = this._document.createElement("style");
    style.innerHTML = `
    .pdf-report-container * {
      opacity: 1 !important;
    }
  `;
    this._document.head.appendChild(style);
    this.loading = true;

    const options = {
      scale: this.scale,
      useCORS: true,
    };

    html2canvas(reportContainer, options)
      .then((canvas) => {
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        });

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 10;

        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const scaleFactor = (pageWidth - margin * 2) / canvasWidth;

        let positionY = 0; // Tracks the current vertical position of the content
        let currentPage = 1;

        const htmlElementsItems = this.getHtmlElementsInfo(
          reportContainer,
          this.scale
        );

        console.log("yPositions", htmlElementsItems);
        const exportDate = new Date().toLocaleDateString();

        // Logo details
        const logoWidth = 15; // Width of the logo in mm
        const logoHeight = 15; // Height of the logo in mm
        const logoX = margin;
        const logoY = margin;
        const logoUrl = this._authService.currentOrganizationValue.logoUrl;

        // Adjusted available height per page
        const availablePageHeight =
          pageHeight - margin * 2 - logoHeight - margin;

        // Add the logo directly using its URL
        pdf.addImage(
          logoUrl,
          "PNG",
          logoX,
          logoY,
          logoWidth,
          logoHeight,
          undefined,
          "FAST"
        );

        // Add the export date to the right at the same height as the logo
        pdf.setFontSize(8);
        pdf.text(
          `Exported: ${exportDate}`,
          pageWidth - margin,
          margin + logoHeight / 2,
          { align: "right" }
        );

        while (positionY < canvasHeight) {
          console.log("positionY (PDF mm):", positionY);

          const remainingHeight = canvasHeight - positionY;

          // Height to render on this page, adjusted for logo
          let renderHeight = Math.min(
            remainingHeight,
            availablePageHeight / scaleFactor
          );
          console.log("renderHeight", renderHeight);

          if (renderHeight !== remainingHeight) {
            renderHeight =
              this.getRenderHeightBasedOnContent(
                htmlElementsItems,
                positionY,
                renderHeight
              ) +
              this.paddingValue * this.scale;
            console.log("updatedRenderHeight1", renderHeight);
          }

          console.log("remainingHeight", remainingHeight);

          console.log("updatedRenderHeight", renderHeight);

          // Create a temporary canvas to hold the current portion
          const croppedCanvas = document.createElement("canvas");
          const croppedContext = croppedCanvas.getContext("2d");

          croppedCanvas.width = canvasWidth;
          croppedCanvas.height = renderHeight;

          // Crop the canvas for the current page
          croppedContext.drawImage(
            canvas,
            0,
            positionY,
            canvasWidth,
            renderHeight,
            0,
            0,
            canvasWidth,
            renderHeight
          );

          const croppedImage = croppedCanvas.toDataURL("image/png");

          // Add the cropped content below the logo
          pdf.addImage(
            croppedImage,
            "PNG",
            margin,
            logoY + logoHeight + margin,
            pageWidth - margin * 2,
            renderHeight * scaleFactor
          );

          // Add the page number
          const textHeight = 8;
          pdf.setFontSize(textHeight);
          const pageNumberY = pageHeight - margin + textHeight / 2;
          pdf.text(`${currentPage}`, pageWidth / 2, pageNumberY, {
            align: "center",
          });

          positionY += renderHeight;

          if (positionY < canvasHeight) {
            pdf.addPage();
            currentPage++;
            // Add logo to the next page
            pdf.addImage(
              logoUrl,
              "PNG",
              logoX,
              logoY,
              logoWidth,
              logoHeight,
              undefined,
              "FAST"
            );
          }
        }

        pdf.save(`${caseName}_${exportDate}_report.pdf`);

        this.loading = false;
        this.cdr.detectChanges();
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  }

  getLastVisibleChild(
    children: any[],
    referenceY: number,
    renderHeight: number
  ): any {
    const visibleChildren = children.filter((child) => {
      return (
        child.yPosition + child.height >= referenceY &&
        child.yPosition < referenceY + renderHeight
      );
    });

    if (visibleChildren.length === 0) {
      return null;
    }

    let index = visibleChildren.length - 1;

    while (index >= 0) {
      const currentChild = visibleChildren[index];

      if (currentChild.children && currentChild.children.length > 0) {
        const nestedLastVisibleChild = this.getLastVisibleChild(
          currentChild.children,
          referenceY,
          renderHeight
        );

        if (
          nestedLastVisibleChild &&
          nestedLastVisibleChild.yPosition + nestedLastVisibleChild.height <=
            referenceY + renderHeight
        ) {
          return nestedLastVisibleChild;
        }
      }

      const isTitle = currentChild.element.tagName.startsWith("H");
      if (
        currentChild.yPosition + currentChild.height <=
        referenceY +
          (!isTitle
            ? renderHeight
            : renderHeight + this.paddingValue * 3 * this.scale)
      ) {
        return currentChild;
      }

      index--; // Move to the previous child
    }

    return null;
  }

  getRenderHeightBasedOnContent(
    items: any[],
    referenceY: number,
    renderHeight: number
  ): number {
    const visibleItems = items.filter((item) => {
      return (
        item.yPosition + item.height >= referenceY &&
        item.yPosition < referenceY + renderHeight
      );
    });

    if (visibleItems.length === 0) {
      return renderHeight;
    }

    let lastVisibleItem = visibleItems[visibleItems.length - 1];

    if (lastVisibleItem.children && lastVisibleItem.children.length > 0) {
      const lastVisibleChild = this.getLastVisibleChild(
        lastVisibleItem.children,
        referenceY,
        renderHeight
      );

      console.log(lastVisibleChild);

      if (lastVisibleChild) {
        const newRenderHeight =
          lastVisibleChild.yPosition + lastVisibleChild.height - referenceY;

        return this.getRenderHeightBasedOnContent(
          [lastVisibleChild],
          referenceY,
          newRenderHeight
        );
      }
    }

    lastVisibleItem = visibleItems[visibleItems.length - 2];
    if (lastVisibleItem) {
      const newRenderHeight =
        lastVisibleItem.yPosition + lastVisibleItem.height - referenceY;
      return newRenderHeight;
    }

    return renderHeight;
  }

  calculateElementHeight(element: HTMLElement, scale: number): number {
    if (element.clientHeight > 0) {
      return element.getBoundingClientRect().height * scale;
    }

    return Array.from(element.children).reduce((total, child) => {
      const childElement = child as HTMLElement;
      return total + childElement.offsetHeight * scale;
    }, 0);
  }

  getHtmlElementsInfo(container: HTMLElement, scale: number) {
    const processElement = (
      element: HTMLElement,
      initialOffset: number,
      inheritedOffset: number = 0
    ): any => {
      const rawOffsetTop = inheritedOffset;

      const height = this.calculateElementHeight(element, scale);
      let children = [];
      if (this.includeChildren(element)) {
        // Check for redundant wrappers
        if (element.children.length === 1) {
          const child = element.children[0] as HTMLElement;
          if (this.includeChildren(child)) {
            const childHeight = this.calculateElementHeight(child, scale);
            const childOffset = child.offsetTop * scale;

            if (childHeight === height && childOffset === rawOffsetTop) {
              return processElement(child, initialOffset, rawOffsetTop);
            }
          }
        }

        let cumulativeOffset = inheritedOffset;

        children = Array.from(element.children).map((child) => {
          const childElement = child as HTMLElement;

          const childInfo = processElement(
            childElement,
            initialOffset,
            cumulativeOffset
          );

          // Update cumulative offset for the next child
          cumulativeOffset += childInfo.height;

          return childInfo;
        });
      }
      return {
        id: element.id,
        element,
        yPosition: rawOffsetTop === 0 ? initialOffset : rawOffsetTop,
        height,
        children,
      };
    };

    const initialOffset = 0;

    // Skip redundant wrappers
    let topLevelChildren = Array.from(container.children);
    if (topLevelChildren.length === 1) {
      let singleChild = topLevelChildren[0] as HTMLElement;
      while (
        singleChild.children.length === 1 &&
        this.calculateElementHeight(singleChild, scale) ===
          this.calculateElementHeight(
            singleChild.children[0] as HTMLElement,
            scale
          ) &&
        singleChild.offsetTop === container.offsetTop
      ) {
        singleChild = singleChild.children[0] as HTMLElement;
      }
      topLevelChildren = Array.from(singleChild.children);
    }

    return topLevelChildren.reduce((acc, child, i) => {
      let inheritedOffset = 0;

      if (i > 0) {
        inheritedOffset = acc[i - 1].yPosition + acc[i - 1].height;
      }

      acc.push(
        processElement(child as HTMLElement, initialOffset, inheritedOffset)
      );
      return acc;
    }, []);
  }

  getAnalystName(analysts: IAnalyst[], id: string) {
    return analysts.find((a) => a.id === id)?.name;
  }
  getAnalystsNames(analysts: IAnalyst[]) {
    return analysts.map((a) => a.name);
  }

  back(currCase: Case) {
    this.router.navigate(["/reports/cases", currCase.id]);
  }

  includeChildren(element: HTMLElement) {
    return (
      element.tagName !== "I" &&
      element.tagName !== "B" &&
      !element.tagName.startsWith("H") &&
      !element.id.includes("label-row")
    );
  }
}
