<ng-container *ngIf="loading">
  <div class="loading-overlay">
    <div class="spinner"></div>
    <p class="export-text">{{ "info.exporting" | translate }}</p>
  </div>
</ng-container>

<ng-container *ngIf="reportService.case$ | async as case">
  <div id="report-header">
    <button
      id="backButton"
      mat-icon-button
      class="btn btn-primary btn-sm float-left"
      (click)="back(case)"
      aria-label="Go Back"
    >
      <i class="ri-arrow-left-line"></i>
    </button>
    <button
      id="printButton"
      mat-icon-button
      class="btn btn-primary btn-sm float-right"
      (click)="openPrintDialog(case?.name)"
      aria-label="Print Report"
    >
      <i class="ri-download-2-line"></i>
    </button>
  </div>
</ng-container>

<div
  class="pdf-report-container"
  *ngIf="reportService.case$ | async as case; else fallbackTemplate"
>
  <ng-container
    *ngIf="{
      samples: reportService.samples$ | async,
      methodTypes: reportService.methodTypes$ | async,
      analysts: reportService.analysts$ | async,
      conclusions: reportService.conclusions$ | async
    } as state"
  >
    <h1 style="text-align: center; padding: 16px" id="title">
      {{ case?.name | uppercase }} {{ "Report" | translate | uppercase }}
    </h1>

    <div class="info-section" id="acquisition-data">
      <div class="row">
        <div class="label">{{ "props.acquired_by" | translate }}:</div>
        <div class="value">
          {{ case?.createdBy?.username || "unknown" }}
        </div>
      </div>
      <div class="row">
        <div class="label">{{ "props.acquired_on" | translate }}:</div>
        <div class="value">{{ case?.createdAt | date : "short" }}</div>
      </div>
      <div class="row">
        <div class="label">{{ "prop.analyzed_by" | translate }}:</div>
        <div class="value">
          {{ getAnalystsNames(state.analysts).join(", ") }}
        </div>
      </div>
    </div>

    <h2 class="section-title" id="case-data-title">
      {{ "title.case_data" | translate | uppercase }}
    </h2>
    <div class="info-section" id="case-data">
      <div *ngFor="let field of case?.caseType.fields" class="row">
        <div class="label" *ngIf="field.type !== 'label'">
          <b>{{ field.displayName }}:</b>
        </div>
        <div class="value">
          <!-- Missing data fields -->
          <div
            *ngIf="
              !case.data[field.name] &&
                field.type !== 'label' &&
                field.type !== 'boolean' &&
                field.type !== 'sectionHeader' &&
                field.type !== 'case_name';
              else fieldData
            "
          >
            <i>{{ "core.unspecified" | translate }}</i>
          </div>
          <!-- Data fields -->
          <ng-template #fieldData>
            <ng-container [ngSwitch]="field.type">
              <div *ngSwitchCase="'selection'">
                <div *ngIf="field.details.multiple">
                  <span
                    class="badge badge-light"
                    *ngFor="let item of case.data[field.name]"
                    >{{ item }}</span
                  >
                </div>
                <div *ngIf="!field.details.multiple">
                  <i>{{ case.data[field.name] }}</i>
                </div>
              </div>
              <div *ngSwitchCase="'date'">
                <i>{{
                  (case.data[field.name] | date : "short") ||
                    "(Invalid date format)"
                }}</i>
              </div>
              <div *ngSwitchCase="'boolean'">
                <i>{{
                  (case.data[field.name] ? "core.yes" : "core.no") | translate
                }}</i>
              </div>
              <!-- <div *ngSwitchCase="'location'">
              <a [href]="getLocationURL(case.data[field.name])">{{
                "button.map" | translate
              }}</a>
            </div> -->
              <div *ngSwitchCase="'number'">
                <i
                  >{{ case.data[field.name] }}
                  <b>{{ field.details.units }}</b></i
                >
              </div>
              <div *ngSwitchCase="'case_name'">
                <i>{{ case.name }}</i>
              </div>
              <div *ngSwitchDefault>
                <div
                  class="multiline-text"
                  style="font-style: italic"
                  *ngIf="field.details.multiline; else singleLine"
                  [innerHTML]="case.data[field.name]"
                ></div>
                <ng-template #singleLine>
                  <i>{{ case.data[field.name] }}</i>
                </ng-template>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>

    <h2
      class="section-title"
      id="analysis-result-title"
      style="padding-left: 5px"
    >
      {{ "label.analysis_results" | translate | uppercase }}
    </h2>
    <ng-container
      *ngIf="reportService.methodTypes$ | async as methodTypes; else noAnalysis"
    >
      <ng-container *ngFor="let methodType of methodTypes">
        <h3 class="subsection-title" id="method-title" style="padding: 0px 5px">
          {{ methodType.name | uppercase }}
        </h3>

        <ng-container
          *ngFor="
            let sample of reportService.getSamplesFromMethodType(methodType.id)
              | async
          "
        >
          <h2
            class="subsection-title"
            style="padding: 16px 5px"
            id="sample-title"
          >
            {{ "core.sample" | translate }} - {{ sample.name }}
          </h2>

          <div
            class="info-section"
            style="display: grid; grid-template-columns: repeat(2, 1fr)"
            id="sample-data"
          >
            <div class="row">
              <div class="label-grid">
                {{ "props.acquired_by" | translate }}:
              </div>
              <div class="value">
                {{
                  getAnalystName(state.analysts, sample.createdById) ||
                    "unknown"
                }}
              </div>
            </div>
            <div class="row">
              <div class="label-grid">{{ "core.num_images" | translate }}:</div>
              <div class="value">
                {{ sample.numImages || "unknown" }}
              </div>
            </div>
            <div class="row">
              <div class="label-grid">{{ "core.device" | translate }}:</div>
              <div class="value">
                {{ sample.device || "unknown" }}
              </div>
            </div>
            <div class="row">
              <div class="label-grid">
                {{ "props.acquired_on" | translate }}:
              </div>
              <div class="value">
                {{ sample.createdAt | date : "short" }}
              </div>
            </div>
          </div>

          <ts-results-table
            id="results-table"
            [sampleAnalyses]="
              reportService.getSampleAnalysesBySampleId(sample.id) | async
            "
            [sampleCounter]="
              reportService.getSampleCountersBySampleId(sample.id) | async
            "
            [sample]="sample"
            [cropInfo]="reportService.getCropInfoBySampleId(sample.id) | async"
            [filters]="reportService.activeFilters$ | async"
            [analysts]="reportService.analysts$ | async"
            [labels]="reportService.labelOptions$ | async"
            [favAssets]="
              reportService.getFavAssetsFromSample(sample.id) | async
            "
            [preview]="true"
            (updatedFilter)="reportService.updateFilter($event)"
          >
          </ts-results-table>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #noAnalysis>
      {{ "info.no_analysis_info" | translate }}</ng-template
    >
    <h2 class="section-title" id="conclusions-title">
      {{ "title.conclusions" | translate | uppercase }}
    </h2>
    <ng-container *ngIf="state.conclusions?.length > 0; else noConclusions">
      <div class="info-section" id="conclusions">{{ state.conclusions }}</div>
    </ng-container>
    <ng-template #noConclusions>
      <div class="no-data">{{ "info.no_conclusions" | translate }}</div>
    </ng-template>
  </ng-container>
</div>
<ng-template #fallbackTemplate>
  <div class="main-container">
    <div class="card">
      <div class="card-body" style="text-align: center">
        <ng-template #notFound> Case not found </ng-template>
      </div>
    </div>
  </div>
</ng-template>
