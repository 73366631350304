import { createAction, props } from "@ngrx/store";
import {
  IAnalyst,
  ICropInfo,
  IFavAssetInfo,
  ILabel,
  IMethodType,
  ISample,
  ISampleAnalysis,
  ISampleCountEntity,
  IReportFilter,
} from "./reports.state";

import { IStep } from "./reports.effects";

export const loadMethodTypes = createAction(
  "[REPORTS] Load methodTypes",
  props<{ caseId: string }>()
);
export const initializeReport = createAction(
  "[REPORTS] Initialize report",
  props<{ caseId: string }>()
);

export const getStepsForMethodType = createAction(
  "[REPORTS] Get steps for specified methodType",
  props<{ methodTypeId: string }>()
);

export const stepsFromMethodTypeFetched = createAction(
  "[REPORTS] Pipeline steps for methodtype fetched",
  props<{ steps: IStep[]; methodTypeId: string }>()
);
export const samplesLoaded = createAction(
  "[REPORTS] Samples from methodType loaded",
  props<{ samples: ISample[] }>()
);

export const methodTypesLoaded = createAction(
  "[REPORTS] MethodTypes loaded",
  props<{ methodTypes: IMethodType[]; caseId: string }>()
);

export const cropInfoLoaded = createAction(
  "[REPORTS] CropInfo loaded",
  props<{ cropInfo: ICropInfo[] }>()
);

export const setActiveMethodType = createAction(
  "[REPORTS] Set methodType to active",
  props<{ methodTypeId: string }>()
);
export const setActiveSample = createAction(
  "[REPORTS] Set sample to active",
  props<{ sampleId: string }>()
);

export const setSamplesForPreview = createAction(
  "[REPORTS] Set sample for preview",
  props<{ sampleIds: string[] }>()
);

export const analystsLoaded = createAction(
  "[REPORTS] Samples analysts loaded",
  props<{ analysts: IAnalyst[] }>()
);

export const labelsLoaded = createAction(
  "[REPORTS] Labels loaded in state",
  props<{ labels: ILabel[] }>()
);

export const reportsError = createAction(
  "[REPORTS] Reports effect failed",
  props<{ error: any }>()
);

export const loadSampleAnalystAnalysis = createAction(
  "[REPORTS] Load sample analysis from an analyst",
  props<{ analystId: string; sampleId: string }>()
);

export const loadSampleAnalysis = createAction(
  "[REPORTS] Load sample analysis",
  props<{ sampleId: string }>()
);

export const analysisForAnalystLoaded = createAction(
  "[REPORTS] Sample analyses from analyst loaded",
  props<{ analyses: ISampleAnalysis[] }>()
);

export const loadSampleCounterForAnalyst = createAction(
  "[REPORTS] Load sample counters for analyst",
  props<{ analyst: IAnalyst; sampleId: string }>()
);

export const loadSampleCounter = createAction(
  "[REPORTS] Load sample counters",
  props<{ sampleId: string }>()
);

export const counterForAnalystLoaded = createAction(
  "[REPORTS] Sample counter from analyst loaded",
  props<{ countInfo: ISampleCountEntity }>()
);

export const favAssetsLoaded = createAction(
  "[REPORTS] Fav assets info loaded",
  props<{ favAssetInfo: IFavAssetInfo[] }>()
);

export const setupFilter = createAction(
  "[REPORTS] Set up filter",
  props<{ filter: IReportFilter }>()
);

export const updateConclusions = createAction(
  "[REPORTS] Update user case conclusions",
  props<{ conclusions: string }>()
);

export const clearReportsState = createAction("[REPORTS] Clear reports state");

export const loadInfoForSamples = createAction(
  "[REPORTS] Load info for samples",
  props<{ sampleIds: string[] }>()
);

export const loadInfoForSample = createAction(
  "[REPORTS] Load info for specified sample",
  props<{ sampleId: string }>()
);

export const loadFavAssets = createAction(
  "[REPORTS] Load favourite assets",
  props<{ sampleId: string }>()
);

export const setLoading = createAction(
  "[REPORTS] Set loading flags",
  props<{ loading: boolean }>()
);
