<div class="card-header">
  <h3 class="bold">
    {{ "label.analysis_preview_panel" | translate | uppercase }}
  </h3>
  <p class="panel-description">
    {{ "label.analysis_preview_panel_description" | translate }}
  </p>
</div>
<div class="card-body" style="padding-bottom: 0">
  <li class="list-group-item">
    <ng-container *ngIf="methodGroups?.length > 0; else noMethodTemplate">
      <ng-container *ngFor="let methodGroup of methodGroups">
        <ng-container
          *ngIf="methodGroup.samples?.length > 0; else noSamplesTemplate"
        >
          <mat-label>{{ methodGroup.name | uppercase }}</mat-label>
          <div *ngFor="let sample of methodGroup.samples">
            <mat-checkbox
              [value]="sample.id"
              [id]="sample.id"
              [checked]="isChecked(sample.id)"
              (change)="updateFilter($event, sample.id)"
            >
              <div>{{ sample.name }}</div>
            </mat-checkbox>
          </div>
        </ng-container>

        <ng-template #noSamplesTemplate>
          <p>{{ "label.no_analysis_for_method_type" | translate }}</p>
        </ng-template>
      </ng-container>
    </ng-container>
  </li>
  <ng-template #noMethodTemplate>
    <p>{{ "label.no_analysis_info" | translate }}</p>
  </ng-template>
</div>

<div class="card-footer">
  <button
    class="btn btn-primary"
    (click)="applyConfig()"
    [disabled]="disablePreviewBtn()"
  >
    {{ "label.preview" | translate }}
  </button>
  <button
    class="btn btn-outline-primary"
    (click)="onExport()"
    data-cy="button-export-report"
    [disabled]="loading"
  >
    <i class="ri-file-download-line"></i>
    {{ "button.export" | translate | uppercase }}
  </button>
</div>
