<div class="filter-container">
  <mat-form-field appearance="outline" class="filter-select">
    <mat-label>{{ filter.displayKey }}</mat-label>
    <mat-select
      [formControl]="filterControl"
      multiple
      (selectionChange)="onSelectionChange()"
    >
      <mat-checkbox
        [checked]="isAllSelected()"
        [indeterminate]="isPartialSelected()"
        (change)="toggleAllOptions()"
        style="padding: 0px 16px"
      >
        {{ "core.all" | translate }}
      </mat-checkbox>

      <mat-option *ngFor="let option of filterOptions" [value]="option.id">
        <i
          class="fa"
          [ngClass]="{
            'ri-user-line': option.type === 'user',
            'ri-robot-line': option.type === 'algorithm',
            'ri-label-line': option.type === 'label'
          }"
        ></i>
        {{ option.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
