import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "ts-report-panel",
  templateUrl: "./report-panel.component.html",
  styleUrls: ["./report-panel.component.scss"],
})
export class ReportPanelComponent implements OnChanges {
  @Input() samples: any[] = [];
  @Input() loading = false;
  @Output() changesApplied = new EventEmitter<string[]>();
  @Output() export = new EventEmitter<boolean>();

  public methodGroups: any[] = [];
  public selectedIds = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.samples || changes.selectedValues) {
      this.updateOptions();
      this.selectedIds = this.samples.filter((s) => s.visible).map((s) => s.id);
    }
  }
  private updateOptions() {
    this.methodGroups = this.samples.reduce((acc, item) => {
      const methodGroupIdx = acc.findIndex((m) => m.id === item.methodTypeId);
      if (methodGroupIdx !== -1) {
        acc[methodGroupIdx] = {
          ...acc[methodGroupIdx],
          samples: [
            ...acc[methodGroupIdx].samples,
            { id: item.id, name: item.name },
          ],
        };
      } else {
        acc.push({
          id: item.methodTypeId,
          name: item.methodTypeName,
          samples: [{ id: item.id, name: item.name }],
        });
      }

      return acc;
    }, []);
  }

  applyConfig() {
    this.changesApplied.emit(this.selectedIds);
  }

  updateFilter(event, id: string) {
    if (event.checked === false) {
      const updatedSelectedIds = this.selectedIds.filter((item) => item !== id);
      this.selectedIds = updatedSelectedIds;
    } else {
      const index = this.selectedIds.findIndex((item) => item === id);
      if (index === -1) {
        this.selectedIds = [...this.selectedIds, id];
      }
    }
  }

  isChecked(id: string) {
    return this.selectedIds.includes(id);
  }

  onExport() {
    this.export.emit(true);
  }

  disablePreviewBtn() {
    return (
      this.methodGroups?.length === 0 ||
      this.loading ||
      (this.selectedIds || []).length === 0
    );
  }
}
