import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "ts-color-palette",
  templateUrl: "./color-palette.component.html",
  styleUrls: ["./color-palette.component.scss"],
})
export class ColorPaletteComponent implements AfterViewInit, OnChanges {
  @Input()
  hue: string;

  @Input()
  color: string;

  @Output()
  colorChange: EventEmitter<string> = new EventEmitter(true);

  @ViewChild("canvas")
  canvas: ElementRef<HTMLCanvasElement>;

  private ctx: CanvasRenderingContext2D;

  private mousedown = false;

  public selectedPosition: { x: number; y: number };
  private isProgrammaticChange = false;

  ngAfterViewInit() {
    this.draw();
    if (this.color) {
      this.updateSelectedPositionFromColor(this.color);
    }
  }

  draw() {
    if (!this.ctx) {
      this.ctx = this.canvas.nativeElement.getContext("2d");
    }
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    this.ctx.fillStyle = this.hue || "rgb(255,255,255,1)";
    this.ctx.fillRect(0, 0, width, height);

    const whiteGrad = this.ctx.createLinearGradient(0, 0, width, 0);
    whiteGrad.addColorStop(0, "rgba(255,255,255,1)");
    whiteGrad.addColorStop(1, "rgba(255,255,255,0)");

    this.ctx.fillStyle = whiteGrad;
    this.ctx.fillRect(0, 0, width, height);

    const blackGrad = this.ctx.createLinearGradient(0, 0, 0, height);
    blackGrad.addColorStop(0, "rgba(0,0,0,0)");
    blackGrad.addColorStop(1, "rgba(0,0,0,1)");

    this.ctx.fillStyle = blackGrad;
    this.ctx.fillRect(0, 0, width, height);

    if (this.selectedPosition) {
      this.ctx.strokeStyle = "white";
      this.ctx.fillStyle = "white";
      this.ctx.beginPath();
      this.ctx.arc(
        this.selectedPosition.x,
        this.selectedPosition.y,
        10,
        0,
        2 * Math.PI
      );
      this.ctx.lineWidth = 5;
      this.ctx.stroke();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.color && !changes?.color?.firstChange) {
      this.isProgrammaticChange = true;
      this.updateSelectedPositionFromColor(changes.color.currentValue);
    }
    if (changes?.hue && !changes?.hue?.firstChange) {
      this.draw();
      if (this.selectedPosition && !this.isProgrammaticChange) {
        this.colorChange.emit(
          this.getColorAtPosition(
            this.selectedPosition.x,
            this.selectedPosition.y
          )
        );
      }
    }
    this.isProgrammaticChange = false;
  }

  onMouseUp(evt: MouseEvent) {
    this.mousedown = false;
    this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
    this.draw();
    this.emitColor(evt.offsetX, evt.offsetY);
  }

  onMouseDown(evt: MouseEvent) {
    this.mousedown = true;
    this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
    this.draw();
  }

  onMouseMove(evt: MouseEvent) {
    if (this.mousedown) {
      this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
      this.draw();
      this.emitColor(evt.offsetX, evt.offsetY);
    }
  }

  emitColor(x: number, y: number) {
    const rgbaColor = this.getColorAtPosition(x, y);
    this.colorChange.emit(rgbaColor); // Emit color using colorChange
  }

  getColorAtPosition(x: number, y: number): string {
    const imageData = this.ctx.getImageData(x, y, 1, 1).data;
    return `rgba(${imageData[0]}, ${imageData[1]}, ${imageData[2]}, 1)`;
  }

  updateSelectedPositionFromColor(color: string) {
    if (!this.ctx || !color) return;

    const rgbaRegex =
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(0|1|0?\.\d+))?\)$/;
    const match = color.match(rgbaRegex);
    if (!match) return;

    const [_, r, g, b, a] = match.map(Number);

    // Iterate over pixels and find closest match
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    let closestMatch: { x: number; y: number; distance: number } | null = null;

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const pixelColor = this.ctx.getImageData(x, y, 1, 1).data;

        const pixelR = pixelColor[0];
        const pixelG = pixelColor[1];
        const pixelB = pixelColor[2];
        const pixelA = pixelColor[3] / 255; // Normalize alpha to 0-1

        // Calculate distance between input color and pixel color
        const distance = Math.sqrt(
          Math.pow(r - pixelR, 2) +
            Math.pow(g - pixelG, 2) +
            Math.pow(b - pixelB, 2) +
            Math.pow(a - pixelA, 2)
        );

        if (!closestMatch || distance < closestMatch.distance) {
          closestMatch = { x, y, distance };
        }
      }
    }

    if (closestMatch) {
      this.selectedPosition = { x: closestMatch.x, y: closestMatch.y };
      this.draw();
    }
  }
}
