import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "ts-color-slider",
  templateUrl: "./color-slider.component.html",
  styleUrls: ["./color-slider.component.scss"],
})
export class ColorSliderComponent implements AfterViewInit {
  @ViewChild("canvas")
  canvas: ElementRef<HTMLCanvasElement>;

  @Input()
  hue: string; // Input to bind the hue value directly from the parent

  @Output()
  color: EventEmitter<string> = new EventEmitter();

  private ctx: CanvasRenderingContext2D;
  private mousedown = false;
  private selectedHeight: number;
  private programmaticUpdate = false; // Flag to track programmatic updates

  ngAfterViewInit() {
    this.draw();
    if (this.hue) {
      this.updateSliderPosition(this.hue);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // When hue input changes, update the slider position
    if (changes?.hue && !changes?.hue?.firstChange) {
      this.updateSliderPosition(this.hue);
    }
  }

  draw() {
    if (!this.ctx) {
      this.ctx = this.canvas.nativeElement.getContext("2d");
    }
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    this.ctx.clearRect(0, 0, width, height);

    const gradient = this.ctx.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, "rgba(255, 0, 0, 1)");
    gradient.addColorStop(0.17, "rgba(255, 255, 0, 1)");
    gradient.addColorStop(0.34, "rgba(0, 255, 0, 1)");
    gradient.addColorStop(0.51, "rgba(0, 255, 255, 1)");
    gradient.addColorStop(0.68, "rgba(0, 0, 255, 1)");
    gradient.addColorStop(0.85, "rgba(255, 0, 255, 1)");
    gradient.addColorStop(1, "rgba(255, 0, 0, 1)");

    this.ctx.beginPath();
    this.ctx.rect(0, 0, width, height);
    this.ctx.fillStyle = gradient;
    this.ctx.fill();
    this.ctx.closePath();

    if (this.selectedHeight) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = "white";
      this.ctx.lineWidth = 5;
      this.ctx.rect(0, this.selectedHeight - 5, width, 10);
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  @HostListener("window:mouseup", ["$event"])
  onMouseUp(evt: MouseEvent) {
    this.mousedown = false;
  }

  onMouseDown(evt: MouseEvent) {
    this.mousedown = true;
    this.selectedHeight = evt.offsetY;
    this.draw();
    this.emitColor(evt.offsetX, evt.offsetY);
  }

  onMouseMove(evt: MouseEvent) {
    if (this.mousedown) {
      this.selectedHeight = evt.offsetY;
      this.draw();
      this.emitColor(evt.offsetX, evt.offsetY);
    }
  }

  emitColor(x: number, y: number) {
    if (this.programmaticUpdate) {
      return;
    }

    const rgbColor = this.getColorAtPosition(x, y);
    this.color.emit(rgbColor);
  }

  getColorAtPosition(x: number, y: number) {
    const imageData = this.ctx.getImageData(x, y, 1, 1).data;
    return (
      "rgba(" + imageData[0] + "," + imageData[1] + "," + imageData[2] + ",1)"
    );
  }

  updateSliderPosition(hue: string) {
    this.programmaticUpdate = true;
    const hslRegex = /^hsl\((\d+),\s*(\d+%)?,\s*(\d+%)?\)$/;
    const match = hue.match(hslRegex);

    if (!match) {
      console.error("Invalid HSL color format:", hue);
      this.programmaticUpdate = false;
      return;
    }

    const numericHue = parseInt(match[1], 10);

    // Calculate the slider's position based on the hue value
    const height = this.canvas.nativeElement.height;
    const selectedHeight = (numericHue / 360) * height;

    this.selectedHeight = selectedHeight;
    this.draw();

    this.programmaticUpdate = false; // Reset flag after updating
  }
}
