<div
  class="method-cards"
  [ngClass]="{ 'preview-mode': preview }"
  id="method-cards"
>
  <div [ngClass]="{ 'card justify-start': !preview }">
    <ng-container *ngIf="!preview">
      <span>
        <ng-container *ngFor="let filter of filters">
          <ts-report-filters
            [items]="filter.filterKey === 'analyst' ? analysts : labels"
            [filter]="filter"
            (applyFilters)="onApplyFilters($event)"
          ></ts-report-filters>
        </ng-container>
      </span>
    </ng-container>
    <!-- SAMPLE ANALYSIS TABLE -->
    <div class="card-header" [ngClass]="{ 'preview-mode': preview }">
      <h3 class="bold" id="sample-remarks_title">
        {{
          "label.sample_remarks" | translate : { name: sample.name } | uppercase
        }}
      </h3>
    </div>

    <div class="card-body">
      <ng-container *ngIf="sampleAnalyses.length > 0; else noSampleData">
        <table
          id="sampleTable"
          [style.--columns-sample]="sampleAnalyses.length"
        >
          <thead>
            <tr>
              <th class="first"></th>
              <ng-container *ngFor="let analysis of sampleAnalyses">
                <th>
                  <i class="fa" [ngClass]="'ri-user-line'"></i>
                  {{ analysis.analystName }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let key of getKeys(sampleAnalyses[0].info)">
              <tr>
                <th>{{ key }}</th>
                <ng-container *ngFor="let analysis of sampleAnalyses">
                  <td>
                    {{ getResultValue(analysis.info, key)?.join(", ") }}
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>

      <ng-template #noSampleData>
        <div class="no-data-message" [ngClass]="{ 'preview-mode': preview }">
          {{ "label.sample_no_remarks" | translate : { name: sample.name } }}
        </div>
      </ng-template>
    </div>
    <!-- COUNTER ANALYSIS TABLE -->
    <div class="card-header" [ngClass]="{ 'preview-mode': preview }">
      <h3 class="bold">
        {{
          "label.sample_results" | translate : { name: sample.name } | uppercase
        }}
      </h3>
    </div>
    <div class="card-body">
      <ng-container *ngIf="sampleCounter.length > 0; else noCounterData">
        <table id="countTable" [style.--columns-count]="sampleCounter.length">
          <thead>
            <tr>
              <th class="first"></th>
              <ng-container *ngFor="let sampleCountItem of sampleCounter">
                <th>
                  <i
                    class="fa"
                    [ngClass]="{
                      'ri-user-line': sampleCountItem.analystType === 'user',
                      'ri-robot-line':
                        sampleCountItem.analystType === 'algorithm'
                    }"
                  ></i>
                  {{ sampleCountItem.analystName }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of getLabels(sampleCounter)">
              <tr>
                <th class="label-th">{{ item.category }}</th>
                <ng-container *ngFor="let result of sampleCounter">
                  <td class="label-td">
                    {{ getLabelTotal(result, item.category) }}
                    ({{ getLabelPerc(result, item.category) | percent }})
                  </td>
                </ng-container>
              </tr>
              <ng-container *ngFor="let sublabel of item.labels">
                <tr>
                  <th class="sublabel">{{ sublabel.name }}</th>
                  <ng-container *ngFor="let result of sampleCounter">
                    <td>
                      {{
                        getSublabelTotal(result, item.category, sublabel.name)
                      }}
                      ({{
                        getSublabelPerc(result, item.category, sublabel.name)
                          | percent
                      }})
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <ng-container *ngFor="let count of sampleCounter">
                <td>
                  {{ count.totalCount }}
                  ({{ getTotalPerc(count.totalCount) | percent }})
                </td>
              </ng-container>
            </tr>
          </tfoot>
        </table>
      </ng-container>
      <ng-template #noCounterData>
        <div class="no-data-message" [ngClass]="{ 'preview-mode': preview }">
          {{ "label.sample_no_results" | translate : { name: sample.name } }}
        </div>
      </ng-template>
    </div>
    <!-- MOSAICS EXAMPLES -->
    <div class="card-header" [ngClass]="{ 'preview-mode': preview }">
      <h3 class="bold">{{ "label.example_crops" | translate | uppercase }}</h3>
    </div>
    <ng-container *ngIf="!preview"
      ><div style="padding-left: 10px">
        {{ "label.cropsAnalyst" | translate : { cropsAnalyst: cropsAnalyst } }}
      </div></ng-container
    >
    <div class="crop-container" [ngClass]="{ 'preview-mode': preview }">
      <ng-container *ngIf="cropInfo.length > 0; else noCropData">
        <ng-container *ngFor="let info of cropInfo; let i = index">
          <div class="label-row" [id]="'label-row-' + i">
            <div class="crop-label" [id]="'label-crop-' + i">
              {{ info.labelValue }}
            </div>
            <div class="image-container">
              <ng-container *ngFor="let cropId of info.cropFileNames">
                <div class="crop-item">
                  <img [src]="getCrop(cropId)" alt="Crop Image" />
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noCropData>
        No crops information available for this sample.
      </ng-template>
    </div>

    <div class="card-header" [ngClass]="{ 'preview-mode': preview }">
      <h3 class="bold">
        {{ "title.favourite_images" | translate | uppercase }}
      </h3>
    </div>
    <div>
      <div
        class="favorite-assets-container"
        [ngClass]="{ 'preview-mode': preview }"
      >
        <ng-container *ngIf="favAssets.length > 0; else noFavAssets"
          ><div class="fav-asset-list">
            <ng-container *ngFor="let asset of favAssets">
              <div class="fav-asset-item">
                <img
                  [src]="getThumbnailUrl(asset.filename) | preprocess | async"
                  [alt]=""
                  class="fav-asset-img"
                />
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #noFavAssets> No favorite assets available. </ng-template>
      </div>
    </div>
  </div>
</div>
