import { Injectable } from "@angular/core";
import {
  CaseAnalysisService,
  LabelCount,
} from "@telespot/analysis-refactor/data-access";
import { AuthService } from "@telespot/web-core";
import { Asset, Finding, PipelineStep } from "@telespot/sdk";
import { BehaviorSubject, Observable } from "rxjs";

import { Label } from "@telespot/domain";
import { TranslateService } from "@ngx-translate/core";
import {
  IFavAssetInfo,
  ILabel,
  IReportFilter,
  ISampleAnalysis,
  ISampleCountEntity,
} from "../../state";
import { Store } from "@ngrx/store";
import * as ReportsSelectors from "../../state/reports.selectors";
import * as ReportsActions from "../../state/reports.actions";

@Injectable({
  providedIn: "root",
})
export class ReportGeneratorService {
  /** PRIVATE VARIABLES */
  public readonly samples$ = this.store$.select(ReportsSelectors.getAllSamples);
  public readonly visibleSamples$ = this.store$.select(
    ReportsSelectors.visibleSamples
  );
  public readonly methodTypes$ = this.store$.select(
    ReportsSelectors.getAllMethodTypes
  );
  public readonly activeMethodType$ = this.store$.select(
    ReportsSelectors.activeMethodType
  );
  public readonly activeSample$ = this.store$.select(
    ReportsSelectors.getActiveSample
  );
  public readonly activeFilters$ = this.store$.select(
    ReportsSelectors.activeFilters
  );
  public readonly analysts$ = this.store$.select(
    ReportsSelectors.selectAnalysts
  );
  public readonly labelOptions$ = this.store$.select(
    ReportsSelectors.activeLabelOptions
  );
  public readonly conclusions$ = this.store$.select(
    ReportsSelectors.caseConclusions
  );
  public readonly samplesInfo$ = this.store$.select(
    ReportsSelectors.getSamplesInfo
  );
  public readonly loading$ = this.store$.select(ReportsSelectors.loading);

  /** PUBLIC VARIABLES */
  public readonly case$ = this.caseService.case$;

  constructor(
    private caseService: CaseAnalysisService,
    private translateService: TranslateService,
    private authService: AuthService,
    private store$: Store
  ) {}

  public getSampleAnalysesBySampleId(
    sampleId: string
  ): Observable<ISampleAnalysis[]> {
    return this.store$.select(
      ReportsSelectors.getSampleAnalysesBySampleId(sampleId)
    );
  }

  public getSampleCountersBySampleId(
    sampleId: string
  ): Observable<ISampleCountEntity[]> {
    return this.store$.select(
      ReportsSelectors.getSampleCountersBySampleId(sampleId)
    );
  }

  public getLabelsByMethodTypeId(methodTypeId: string): Observable<ILabel[]> {
    return this.store$.select(
      ReportsSelectors.getLabelsByMethodTypeId(methodTypeId)
    );
  }

  public getFavAssetsFromSample(sampleId: string): Observable<IFavAssetInfo[]> {
    return this.store$.select(
      ReportsSelectors.getFavAssetsFromSample(sampleId)
    );
  }

  public getAnalystFromCrops(sampleId: string): Observable<string> {
    return this.store$.select(ReportsSelectors.getAnalystFromCrops(sampleId));
  }

  public setActiveMethodType(id: string) {
    this.store$.dispatch(
      ReportsActions.setActiveMethodType({ methodTypeId: id })
    );
  }

  public setActiveSample(id: string) {
    this.store$.dispatch(ReportsActions.setActiveSample({ sampleId: id }));
  }

  public getCropInfoBySampleId(id: string) {
    return this.store$.select(ReportsSelectors.getCropsBySampleId(id));
  }

  public updateFilter(filter: IReportFilter) {
    this.store$.dispatch(ReportsActions.setupFilter({ filter }));
  }

  public onConclusionsChange(conclusions: string): void {
    this.store$.dispatch(ReportsActions.updateConclusions({ conclusions }));
  }

  public initializeReport(caseId: string) {
    this.store$.dispatch(ReportsActions.initializeReport({ caseId }));
  }

  public clearReportState() {
    this.store$.dispatch(ReportsActions.clearReportsState());
  }

  public getNumSamples(methodTypeId: string) {
    return this.store$.select(
      ReportsSelectors.numSamplesByMethodType(methodTypeId)
    );
  }

  public loadSamplesInfo(sampleIds) {
    return this.store$.dispatch(
      ReportsActions.loadInfoForSamples({ sampleIds })
    );
  }

  public getAuthUserId() {
    return this.authService.currentUser.id;
  }

  public getSamplesFromMethodType(id: string) {
    return this.store$.select(ReportsSelectors.getSamplesForMethodType(id));
  }

  public filterLabels(labels: Label[]) {
    const filteredLabelsMap = new Map();
    const lang = this.translateService.currentLang;

    labels.forEach((label) => {
      const existingLabel = filteredLabelsMap.get(label.uuid);

      if (!existingLabel) {
        filteredLabelsMap.set(label.uuid, label);
      } else if (
        label.lang === lang ||
        (existingLabel.lang !== lang && label.lang === "en")
      ) {
        filteredLabelsMap.set(label.uuid, label);
      }
    });

    return Array.from(filteredLabelsMap.values());
  }

  public getHighestVersionFindings(findings: Finding[]) {
    //Review Duplicated function from analysis.service in analysis-refactor project
    const highestVersionMap = new Map();

    findings.forEach((finding) => {
      const analysisId = finding.analysis.id;
      const pipelineStepId = finding.pipelineStep.id;

      const key = `${analysisId}-${pipelineStepId}`;

      if (
        !highestVersionMap.has(key) ||
        finding.version > highestVersionMap.get(key).version
      ) {
        highestVersionMap.set(key, finding);
      }
    });

    const uniqueFindings = Array.from(highestVersionMap.values());

    return uniqueFindings;
  }

  //Review
  // private async _getCustomLabels(steps: PipelineStep[], labels: LabelCount[]) {
  //   const stepUUIDs = new Set();

  //   steps.forEach((step) => {
  //     step.params.categorization.forEach((category) => {
  //       category.options.forEach((option) => {
  //         stepUUIDs.add(option);
  //       });
  //     });
  //   });

  //   const missingUUIDs = labels
  //     .filter((result) => !stepUUIDs.has(result.labelId))
  //     .map((result) => result.labelId);

  //   const missingValues = [];
  //   await Promise.all(
  //     missingUUIDs.map(async (uuid) => {
  //       const value = await this._getLabelValue(uuid);
  //       this._labelDict[uuid] = value;
  //       missingValues.push(value);
  //     })
  //   );

  //   return missingValues;
  // }

  private getFavAssetInfo(favAssetsIds: string[], assets: Asset[]) {
    return (favAssetsIds || []).map((id) => {
      const asset = assets.find((a) => a.id === id);
      return { id: asset.id, filename: asset.thumbnail };
    });
  }
}
