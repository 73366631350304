import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, Action } from "@ngrx/store";
import { EMPTY, from, of, Observable, forkJoin } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
  take,
  tap,
} from "rxjs/operators";
import * as ReportsActions from "./reports.actions";
import {
  CaseTopology,
  MethodTypeTopology,
  SampleTopology,
  AnalysisStateTopology,
  ProtocolPipelineTopology,
  PipelineTopology,
  ObjectTopology,
  PipelineStepTopology,
  LabelTopology,
  AnalysisTopology,
  FindingTopology,
  CaseTypeTopology,
  AssetTypeTopology,
  AssetTopology,
  DeviceTopology,
  DeviceTypeTopology,
} from "@telespot/infrastructure/parse";
import Parse from "parse";
import {
  ISample,
  IAnalyst,
  ISampleAnalysis,
  ISampleAnalysisInfo,
  ISampleCountEntity,
} from "./reports.state";
import {
  getPipelinesForSample,
  getAIAnalysts,
  selectAnalysts,
  getMethodTypeFromSample,
  getLabelsByMethodTypeId,
} from "./reports.selectors";
import { StepAction, StepTask } from "@telespot/domain";
import { ReportMapper } from "../services/report-generator/report-generator.mapper";
import { ReportGeneratorService } from "../services/report-generator/report-generator.service";
import { User, CloudFunctions, Sample, Algorithms } from "@telespot/sdk";
import { MosaicService } from "@telespot/web-core";

export interface IStep {
  id: string;
  methodTypeId: string;
  pipelineId: string;
  type: StepTask;
  assetSpecific: boolean;
  params: any[];
}
@Injectable()
export class ReportsEffects {
  private Case: typeof Parse.Object = Parse.Object.extend(CaseTopology.TABLE);
  private Sample: typeof Parse.Object = Parse.Object.extend(
    SampleTopology.TABLE
  );

  private MethodType: typeof Parse.Object = Parse.Object.extend(
    MethodTypeTopology.TABLE
  );

  private PipelineSteps: typeof Parse.Object = Parse.Object.extend(
    PipelineStepTopology.TABLE
  );

  constructor(
    private readonly actions$: Actions,
    private store$: Store,
    private readonly reportService: ReportGeneratorService,
    private mosaicService: MosaicService
  ) {}

  initializeReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.initializeReport),
      switchMap(({ caseId }) => {
        return of(ReportsActions.loadMethodTypes({ caseId }));
      })
    )
  );

  loadMethodTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.loadMethodTypes),
      switchMap(({ caseId }) =>
        from(this.getCaseMethodTypes(caseId)).pipe(
          map((methodTypes) =>
            ReportsActions.methodTypesLoaded({ methodTypes, caseId })
          ),
          catchError((error) =>
            of(
              ReportsActions.reportsError({
                error: `[loadMethodTypes]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  loadSamples$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.methodTypesLoaded),
      switchMap(({ caseId }) =>
        from(this.getSamples(caseId)).pipe(
          map((samples) =>
            ReportsActions.samplesLoaded({
              samples,
            })
          ),
          catchError((error) =>
            of(
              ReportsActions.reportsError({
                error: `[loadSamples]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  loadAnalysts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.samplesLoaded),
      switchMap(({ samples }) =>
        from(this.getSamplesAnalysts(samples)).pipe(
          map((analysts) => ReportsActions.analystsLoaded({ analysts })),
          catchError((error) =>
            of(
              ReportsActions.reportsError({
                error: `[loadAnalysts]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  fetchInfoFromMethodTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.methodTypesLoaded),
      switchMap(({ methodTypes }) => {
        return methodTypes.map((methodType) =>
          ReportsActions.getStepsForMethodType({ methodTypeId: methodType.id })
        );
      })
    )
  );

  getStepsForMethodType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.getStepsForMethodType),
      mergeMap(({ methodTypeId }) =>
        from(this.getStepsForMethodType(methodTypeId)).pipe(
          map((steps) =>
            ReportsActions.stepsFromMethodTypeFetched({ steps, methodTypeId })
          ),
          catchError((error) =>
            of(
              ReportsActions.reportsError({
                error: `[getStepsForMethodType]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  loadLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.stepsFromMethodTypeFetched),
      switchMap(({ steps }) => {
        const labelUuids = ReportMapper.getLabelUuidsFromSteps(steps);
        return from(this.fetchLabelsInBatches(labelUuids)).pipe(
          map((allLabels) => this.reportService.filterLabels(allLabels)),
          map((filteredLabels) =>
            ReportMapper.toStateLabels(filteredLabels, steps)
          ),
          map((labels) => ReportsActions.labelsLoaded({ labels })),
          catchError((error) =>
            of(
              ReportsActions.reportsError({
                error: `[loadLabels]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  loadInfoForSamples$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.loadInfoForSamples),
      mergeMap(
        ({ sampleIds }) =>
          of(sampleIds).pipe(
            withLatestFrom(
              this.store$.select(getMethodTypeFromSample(sampleIds[0]))
            )
          ),
        (sampleIds, data) => data
      ),
      mergeMap(([sampleIds, methodTypeId]) => {
        if (sampleIds?.length < 1) return EMPTY;
        return [
          ReportsActions.setSamplesForPreview({ sampleIds }),
          ReportsActions.setActiveSample({ sampleId: sampleIds[0] }),
          ReportsActions.setActiveMethodType({ methodTypeId }),
          ReportsActions.setLoading({ loading: true }),
          ...sampleIds.map((id) =>
            ReportsActions.loadInfoForSample({
              sampleId: id,
            })
          ),
        ];
      })
    )
  );

  loadInfoForSpecifiedSample$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.loadInfoForSample),
      mergeMap(({ sampleId }) => {
        return [
          ReportsActions.loadSampleAnalysis({ sampleId }),
          ReportsActions.loadSampleCounter({ sampleId }),
          ReportsActions.loadFavAssets({ sampleId }),
        ];
      })
    )
  );

  loadInitialAnalystFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.setActiveMethodType),
      withLatestFrom(this.store$.select(selectAnalysts)),
      switchMap(([{ methodTypeId }, analysts]) => {
        if (!methodTypeId) return EMPTY;
        const newFilter = {
          filterKey: "analyst",
          displayKey: "analyst",
          selectedOptions: analysts.map((an) => ({ id: an.id, name: an.name })),
          methodTypeId: methodTypeId,
        };
        return of(ReportsActions.setupFilter({ filter: newFilter }));
      })
    )
  );

  loadInitialLabelFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.setActiveMethodType),
      mergeMap(
        ({ methodTypeId }) =>
          of(methodTypeId).pipe(
            withLatestFrom(
              this.store$.select(getLabelsByMethodTypeId(methodTypeId))
            )
          ),
        (methodTypeId, data) => data
      ),
      mergeMap(([methodTypeId, labels]) => {
        if (!methodTypeId) return EMPTY;
        const newFilter = {
          filterKey: "label",
          displayKey: "label",
          selectedOptions: labels.reduce((acc, l) => {
            const newOption = {
              id: l.includeInCounter ? l.uuid : l.categoryUuid,
              name: l.includeInCounter ? l.value : l.category,
            };
            const exists = acc.find((o) => o.id === newOption.id);
            if (!exists) acc.push(newOption);
            return acc;
          }, []),
          methodTypeId,
        };
        return of(ReportsActions.setupFilter({ filter: newFilter }));
      })
    )
  );

  loadInitialSampleAnalyses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.loadSampleAnalysis),
      withLatestFrom(this.store$.select(selectAnalysts)),
      switchMap(([{ sampleId }, analysts]) => {
        return analysts
          .filter((an) => an.type === "user")
          .map((analyst) =>
            ReportsActions.loadSampleAnalystAnalysis({
              analystId: analyst.id,
              sampleId,
            })
          );
      })
    )
  );

  loadInitialSampleCounter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.loadSampleCounter),
      withLatestFrom(this.store$.select(selectAnalysts)),
      switchMap(([{ sampleId }, analysts]) => {
        return analysts.map((analyst) =>
          ReportsActions.loadSampleCounterForAnalyst({
            analyst,
            sampleId,
          })
        );
      })
    )
  );

  getAnalysisForAnalyst$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.loadSampleAnalystAnalysis),
      mergeMap(({ analystId, sampleId }) =>
        this.store$.select(getPipelinesForSample(sampleId)).pipe(
          filter(
            (pipelineIds) => pipelineIds !== undefined && pipelineIds.length > 0
          ),
          take(1),
          switchMap((pipelineIds) =>
            this.getSampleAnalyses(sampleId, analystId, pipelineIds).pipe(
              map((sampleAnalyses) =>
                ReportsActions.analysisForAnalystLoaded({
                  analyses: sampleAnalyses,
                })
              ),
              catchError((error) =>
                of(
                  ReportsActions.reportsError({
                    error: `[getAnalysisForAnalyst]: ${error.message}`,
                  })
                )
              )
            )
          )
        )
      )
    )
  );

  getSampleCounterForAnalyst$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.loadSampleCounterForAnalyst),
      mergeMap(({ analyst, sampleId }) => {
        const sample = this.Sample.createWithoutData(sampleId) as Sample;
        const createdType = analyst.type === "user" ? User : Algorithms;
        const createdByObject = createdType.createWithoutData(analyst.id);

        return from(
          CloudFunctions.GetSampleAnalsysStats(
            sample,
            createdByObject.toPointer()
          )
        ).pipe(
          map((sampleStats) =>
            ReportsEffects.toISampleCount(sampleId, analyst.id, sampleStats)
          ),
          map((countInfo) =>
            ReportsActions.counterForAnalystLoaded({
              countInfo,
            })
          ),
          catchError((error) =>
            of(
              ReportsActions.reportsError({
                error: `[getSampleCounterForAnalyst]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  loadCrops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.counterForAnalystLoaded),
      withLatestFrom(this.store$.select(getAIAnalysts)),
      mergeMap(([{ countInfo }, AIAnalysts]) => {
        const analyst = AIAnalysts.find((an) => an.id === countInfo.analystId);
        if (!analyst || countInfo.labelCount.length === 0) return EMPTY;
        return from(
          this.getCrops(
            countInfo.sampleId,
            countInfo.analystId,
            countInfo.labelCount.map((lc) => lc.labelId)
          )
        ).pipe(
          map((cropInfo) => ReportsActions.cropInfoLoaded({ cropInfo })),
          catchError((error) =>
            of(
              ReportsActions.reportsError({
                error: `[loadCrops]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  loadFavAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.loadFavAssets),
      mergeMap(({ sampleId }) => {
        const userId = this.reportService.getAuthUserId();
        return from(this.getFavAssets(userId, sampleId)).pipe(
          map((favAssetInfo) =>
            ReportsActions.favAssetsLoaded({ favAssetInfo })
          ),
          catchError((error) =>
            of(
              ReportsActions.reportsError({
                error: `[loadFavAssets]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  saveCropsInStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.cropInfoLoaded),
      mergeMap(({ cropInfo }) => {
        const filenames = cropInfo.map((c) => c.id);
        const cropBlobs$ = filenames.map((f) => {
          return this.mosaicService.getCrop(f);
        });
        return forkJoin(cropBlobs$).pipe(
          mergeMap((cropBlobs) => {
            cropBlobs.map((blob, index) => {
              localStorage.setItem(
                `crop_${cropInfo[index].id}`,
                URL.createObjectURL(blob)
              );
            });
            return of(ReportsActions.setLoading({ loading: false }));
          }),
          catchError((error) => {
            console.error("Error fetching or saving crop blob:", error);
            return of(
              ReportsActions.reportsError({
                error: null,
              })
            );
          })
        );
      })
    )
  );

  clearStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActions.clearReportsState),
      tap(() => {
        Object.keys(localStorage)
          .filter((item) => item.startsWith("crop_"))
          .forEach((item) => localStorage.removeItem(item));
      }),
      map(() => {
        return { type: "LocalStorageCleared" } as Action;
      })
    )
  );

  public static toSampleItem(sample) {
    return {
      id: sample.id,
      numImages: sample.get(SampleTopology.NUM_ASSETS),
      name: sample.get(SampleTopology.NAME),
      methodTypeId: sample.get(SampleTopology.METHOD_TYPE).id,
      active: false,
      visible: false,
      createdById: sample.get(SampleTopology.CREATED_BY).id,
      device: sample
        .get(SampleTopology.DEVICE)
        ?.get(DeviceTopology.TYPE)
        ?.get(DeviceTypeTopology.NAME),
      createdAt: new Date(sample.get(ObjectTopology.CREATED_AT)),
    };
  }

  public static toMethodTypeItem(methodType) {
    return {
      id: methodType.id,
      name: methodType.get(MethodTypeTopology.NAME),
      assetType: methodType
        .get(MethodTypeTopology.ASSET_TYPE)
        .get(AssetTypeTopology.TYPE),
      active: false,
    };
  }

  public static toStepItem(step, methodTypeId): IStep {
    const params = step.get(PipelineStepTopology.PARAMS)?.categorization ?? [];
    return {
      id: step.id,
      methodTypeId: methodTypeId,
      pipelineId: step.get(PipelineStepTopology.PIPELINE).id,
      type: step.get(PipelineStepTopology.TASK),
      assetSpecific:
        step.get(PipelineStepTopology.GENERATEFINDING) ===
        StepAction.ASSET_CREATED,
      params,
    };
  }

  public static toIAnalyst(analysisState): IAnalyst {
    const user = analysisState.get(AnalysisStateTopology.USER);
    const algorithm = analysisState.get(AnalysisStateTopology.ALGORITHM);
    const isAI = !!algorithm;
    return {
      id: isAI ? algorithm.id : user.id,
      name: isAI ? algorithm.name : user.username,
      type: isAI ? "algorithm" : "user",
    };
  }

  public static toISampleAnalysis(
    sampleId: string,
    analystId: string,
    content: any = {},
    params: any
  ): ISampleAnalysis {
    const info: ISampleAnalysisInfo = {};
    const { options, value } = content;

    (params || []).forEach(({ category, options: categoryOptions }) => {
      if (options) {
        const matchedOptions = categoryOptions.filter((option) =>
          Object.keys(options).includes(option)
        );
        if (matchedOptions.length > 0) {
          info[category] = matchedOptions;
        }
      } else if (value) {
        info[category] = [value];
      }
    });

    return {
      sampleId,
      analystId,
      info,
    };
  }

  public static toISampleCount(
    sampleId: string,
    analystId: string,
    stats: any = {}
  ): ISampleCountEntity {
    return {
      sampleId,
      analystId,
      labelCount: stats.labelCount.map((l) => ({
        labelId: l.labelId,
        count: l.rois,
      })),
      totalCount: stats.total,
    };
  }

  public static toICropInfo(result) {
    const { creatorId, id, sampleId, labels } = result;
    return {
      labelId: Object.keys(labels)[0],
      id,
      sampleId,
      analystId: creatorId,
    };
  }

  public static toIFavAssetInfo(result, sampleId: string, analystId: string) {
    const assetFile = result.get(AssetTopology.ASSET_FILE);
    return {
      id: result.id,
      filename: assetFile,
      sampleId,
      analystId,
    };
  }

  private async getCrops(
    sampleId: string,
    analystId: string,
    labels: string[]
  ) {
    const results = await Promise.all(
      labels.map((l) =>
        this.mosaicService.getRoisFromSample(sampleId, analystId, 5, l)
      )
    );
    return results.flatMap((r) => r.items).map(ReportsEffects.toICropInfo);
  }

  private async getSamples(caseId: string) {
    const sampleQuery = new Parse.Query(SampleTopology.TABLE)
      .equalTo(SampleTopology.CASE, this.Case.createWithoutData(caseId))
      .include(`${SampleTopology.DEVICE}.${DeviceTopology.TYPE}`);

    const results = await sampleQuery.find();

    return results.map(ReportsEffects.toSampleItem);
  }

  private async getFavAssets(createdById: string, sampleId: string) {
    const analysisStateQuery = new Parse.Query(AnalysisStateTopology.TABLE)
      .equalTo(
        AnalysisStateTopology.SAMPLE,
        this.Sample.createWithoutData(sampleId)
      )
      .equalTo(AnalysisStateTopology.USER, User.createWithoutData(createdById))
      .include(AnalysisStateTopology.FAVORITE_ASSETS);
    const result = await analysisStateQuery.first();

    const assetIds = result.get(AnalysisStateTopology.FAVORITE_ASSETS);

    const assetQuery = new Parse.Query(AssetTopology.TABLE).containedIn(
      ObjectTopology.ID,
      assetIds
    );

    const assets = await assetQuery.find();

    return assets.map((a) =>
      ReportsEffects.toIFavAssetInfo(a, sampleId, createdById)
    );
  }

  private async getSamplesAnalysts(samples: ISample[]) {
    const allResults = [];
    for (const sample of samples) {
      const analysisStateQuery = new Parse.Query(AnalysisStateTopology.TABLE)
        .equalTo(
          AnalysisStateTopology.SAMPLE,
          this.Sample.createWithoutData(sample.id)
        )
        .include(AnalysisStateTopology.USER)
        .include(AnalysisStateTopology.ALGORITHM);

      const results = await analysisStateQuery.find();
      if (results) allResults.push(...results);
    }
    return allResults.map(ReportsEffects.toIAnalyst);
  }

  private async getCaseMethodTypes(caseId: string) {
    const caseQuery = new Parse.Query(CaseTopology.TABLE).equalTo(
      ObjectTopology.ID,
      caseId
    );

    const caseObject = await caseQuery.first();

    const caseTypeQuery = new Parse.Query(CaseTypeTopology.TABLE).equalTo(
      ObjectTopology.ID,
      caseObject.get(`${CaseTopology.CASE_TYPE}`).id
    );

    const caseTypeObject = await caseTypeQuery.first();

    const methodTypesPointers = caseTypeObject.get(
      `${CaseTypeTopology.METHOD_TYPES}`
    );

    const methodTypeIds = methodTypesPointers.map(
      (pointer: Parse.Object) => pointer.id
    );

    const methodTypesQuery = new Parse.Query(
      MethodTypeTopology.TABLE
    ).containedIn(ObjectTopology.ID, methodTypeIds);

    const matchingMethodTypes = await methodTypesQuery.find();

    return matchingMethodTypes.map(ReportsEffects.toMethodTypeItem);
  }

  private async getStepsForMethodType(methodTypeId: string) {
    const protocolPipelineQuery = new Parse.Query(
      ProtocolPipelineTopology.TABLE
    ).equalTo(
      ProtocolPipelineTopology.METHOD_TYPE,
      this.MethodType.createWithoutData(methodTypeId)
    );

    const pipelineQuery = new Parse.Query(
      PipelineTopology.TABLE
    ).matchesKeyInQuery(
      ObjectTopology.ID,
      `${ProtocolPipelineTopology.PIPELINE}.${ObjectTopology.ID}`,
      protocolPipelineQuery
    );

    const pipelineStepsQuery = new Parse.Query(
      PipelineStepTopology.TABLE
    ).matchesQuery(PipelineStepTopology.PIPELINE, pipelineQuery);

    const results = await pipelineStepsQuery.find();

    return results.map((step) => ReportsEffects.toStepItem(step, methodTypeId));
  }

  private getSampleAnalyses(
    sampleId: string,
    analystId: string,
    pipelineIds: string[]
  ): Observable<ISampleAnalysis[]> {
    //Review duplicated code in analysis.service from analysis-refactor project
    const sample = this.Sample.createWithoutData(sampleId);
    const createdByObject = User.createWithoutData(analystId);

    const pipelinesQuery = new Parse.Query(PipelineTopology.TABLE).containedIn(
      ObjectTopology.ID,
      pipelineIds
    );

    const analysisQuery = new Parse.Query(AnalysisTopology.TABLE)
      .equalTo(AnalysisTopology.SAMPLE, sample)
      .equalTo(AnalysisTopology.CREATED_BY, createdByObject)
      .exists(AnalysisTopology.CREATED_BY)
      .matchesQuery(AnalysisTopology.PIPELINE, pipelinesQuery)
      .exists(AnalysisTopology.PIPELINE)
      .include([AnalysisTopology.PIPELINE])
      .descending(ObjectTopology.CREATED_AT)
      .doesNotExist(AnalysisTopology.ASSET);

    return from(
      analysisQuery
        .find()
        // Filter analysis with same analysis type, since they are ordered by creation, only the latest will be mapped
        .then((analysis) =>
          analysis.filter(
            (an, i, array) =>
              array.findIndex(
                (v) =>
                  v.get(AnalysisTopology.PIPELINE).id ===
                  an.get(AnalysisTopology.PIPELINE).id
              ) === i
          )
        )
        .then((analysis) => {
          const batchSize = 100;
          let skip = 0;
          const findingsFetched = [];

          const fetchNextBatch = (): Promise<any> => {
            const findingsQuery = new Parse.Query(FindingTopology.TABLE)
              .containedIn(FindingTopology.ANALYSIS, analysis)
              .equalTo(FindingTopology.CREATOR_ID, analystId)
              .include([FindingTopology.PIPELINE_STEP])
              .descending(FindingTopology.VERSION)
              .skip(skip)
              .limit(batchSize);

            return findingsQuery.find().then((findings) => {
              if (findings.length > 0) {
                findingsFetched.push(...findings);
                skip += batchSize;
                return fetchNextBatch();
              } else {
                return Promise.resolve({
                  findingsFetched,
                });
              }
            });
          };

          return fetchNextBatch().then(({ findingsFetched }) => {
            const lastUpdatedFindings =
              this.reportService.getHighestVersionFindings(findingsFetched);
            return lastUpdatedFindings.map((f) =>
              ReportsEffects.toISampleAnalysis(
                sampleId,
                analystId,
                f.get(FindingTopology.DATA)?.content[0],
                f.get(FindingTopology.PIPELINE_STEP)?.params?.categorization
              )
            );
          });
        })
    );
  }

  public async fetchLabelsInBatches(labelUuids, batchSize = 100) {
    let skip = 0;
    const allLabels = [];

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const labelsBatch = await new Parse.Query(LabelTopology.TABLE)
        .containedIn(LabelTopology.UUID, labelUuids)
        .limit(batchSize)
        .skip(skip)
        .ascending(ObjectTopology.CREATED_AT)
        .find();

      if (labelsBatch.length === 0) {
        break;
      }

      allLabels.push(...labelsBatch);

      skip += batchSize;
    }
    return allLabels;
  }
}
