import { createReducer, on } from "@ngrx/store";
import {
  analystAdapter,
  initialReportState,
  sampleAdapter,
  ReportsState,
  labelAdapter,
  sampleAnalysisAdapter,
  sampleCounterAdapter,
  methodTypeAdapter,
  ILabel,
  ISampleAnalysisInfo,
  cropInfoAdapter,
  reportsFiltersAdapter,
  favAssetsAdapter,
} from "./reports.state";
import * as ReportsActions from "./reports.actions";

export const REPORTS_FEATURE_KEY = "reports";

export function getSamples(state: ReportsState) {
  return Object.values(state.samples.entities ?? {});
}

export function getAnalysts(state: ReportsState) {
  return Object.values(state.analysts.entities ?? {});
}

export function getLabels(state: ReportsState) {
  return Object.values(state.labels.entities ?? {});
}

export function getMethodTypes(state: ReportsState) {
  return Object.values(state.methodTypes.entities ?? {});
}

export function getSampleAnalyses(state: ReportsState) {
  return Object.values(state.sampleAnalysis.entities ?? {});
}

export function getSampleCounters(state: ReportsState) {
  return Object.values(state.sampleCounter.entities ?? {});
}

export function getCropInfo(state: ReportsState) {
  return Object.values(state.cropInfo.entities ?? {});
}

export function getFilters(state: ReportsState) {
  return Object.values(state.reportsFilters.entities ?? {});
}
export function getFavAssets(state: ReportsState) {
  return Object.values(state.favAssets.entities ?? {});
}
export function mapSampleAnalysisInfo(
  info: ISampleAnalysisInfo,
  labels: ILabel[]
) {
  const labelsMap = new Map(labels.map((label) => [label.uuid, label.value]));
  const categoriesMap = new Map(
    labels.map((label) => [label.categoryUuid, label.category])
  );

  return Object.entries(info).reduce((acc, [categoryUuid, labelUuids]) => {
    const category = categoriesMap.get(categoryUuid);
    if (!category) {
      return acc;
    }

    const labelValues = labelUuids.map(
      (labelUuid) => labelsMap.get(labelUuid) || labelUuid
    );

    acc[category] = labelValues;
    return acc;
  }, {});
}

export const reportsReducer = createReducer(
  initialReportState,
  on(ReportsActions.samplesLoaded, (state, { samples }) => ({
    ...state,
    samples: sampleAdapter.upsertMany(samples, state.samples),
  })),
  on(ReportsActions.methodTypesLoaded, (state, { methodTypes }) => ({
    ...state,
    methodTypes: methodTypeAdapter.upsertMany(methodTypes, state.methodTypes),
  })),
  on(ReportsActions.analystsLoaded, (state, { analysts }) => ({
    ...state,
    analysts: analystAdapter.upsertMany(analysts, state.analysts),
  })),
  on(ReportsActions.labelsLoaded, (state, { labels }) => ({
    ...state,
    labels: labelAdapter.upsertMany(labels, state.labels),
  })),
  on(ReportsActions.cropInfoLoaded, (state, { cropInfo }) => ({
    ...state,
    cropInfo: cropInfoAdapter.upsertMany(cropInfo, state.cropInfo),
  })),
  on(ReportsActions.favAssetsLoaded, (state, { favAssetInfo }) => ({
    ...state,
    favAssets: favAssetsAdapter.upsertMany(favAssetInfo, state.favAssets),
  })),
  on(ReportsActions.setActiveMethodType, (state, { methodTypeId }) => {
    const currentActive = getMethodTypes(state).find((m) => m.active);

    const updates = [];
    if (currentActive) {
      updates.push({ id: currentActive.id, changes: { active: false } });
    }

    updates.push({ id: methodTypeId, changes: { active: true } });

    return {
      ...state,
      methodTypes: methodTypeAdapter.updateMany(updates, state.methodTypes),
    };
  }),
  on(ReportsActions.setActiveSample, (state, { sampleId }) => {
    const currentActive = getSamples(state).find((s) => s.active);

    const updates = [];
    if (currentActive) {
      updates.push({ id: currentActive.id, changes: { active: false } });
    }

    updates.push({ id: sampleId, changes: { active: true } });

    return {
      ...state,
      samples: sampleAdapter.updateMany(updates, state.samples),
    };
  }),
  on(ReportsActions.setActiveSample, (state, { sampleId }) => {
    const mt = getSamples(state).find((m) => m.id === sampleId);

    return {
      ...state,
      samples: sampleAdapter.updateOne(
        { id: mt.id, changes: { active: true } },
        state.samples
      ),
    };
  }),
  on(ReportsActions.analysisForAnalystLoaded, (state, { analyses }) => {
    const sampleAnalysis = analyses.reduce((acc, an) => {
      const analysisIdx = acc.findIndex(
        (a) => a.analystId === an.analystId && a.sampleId === an.sampleId
      );
      if (analysisIdx !== -1) {
        acc[analysisIdx] = {
          ...acc[analysisIdx],
          info: {
            ...acc[analysisIdx].info,
            ...an.info,
          },
        };
      } else {
        acc.push(an);
      }

      return acc;
    }, []);
    return {
      ...state,
      sampleAnalysis: sampleAnalysisAdapter.upsertMany(
        sampleAnalysis,
        state.sampleAnalysis
      ),
    };
  }),
  on(ReportsActions.counterForAnalystLoaded, (state, { countInfo }) => ({
    ...state,
    sampleCounter: sampleCounterAdapter.upsertOne(
      countInfo,
      state.sampleCounter
    ),
  })),
  on(ReportsActions.setupFilter, (state, { filter }) => ({
    ...state,
    reportsFilters: reportsFiltersAdapter.upsertOne(
      filter,
      state.reportsFilters
    ),
  })),
  on(ReportsActions.updateConclusions, (state, { conclusions }) => ({
    ...state,
    conclusions,
  })),
  on(ReportsActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(ReportsActions.clearReportsState, (state) => ({
    ...initialReportState,
  })),
  on(ReportsActions.setSamplesForPreview, (state, { sampleIds }) => {
    const visibleSamples = getSamples(state).filter(
      (s) => s.visible && !sampleIds.includes(s.id)
    );

    const updates = [];
    if (visibleSamples.length > 0) {
      visibleSamples.forEach((s) => {
        updates.push({ id: s.id, changes: { visible: false } });
      });
    }

    sampleIds.forEach((id) => {
      updates.push({ id, changes: { visible: true } });
    });

    return {
      ...state,
      samples: sampleAdapter.updateMany(updates, state.samples),
    };
  })
);
