import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { IReportFilter } from "@telespot/reports/data-access";

@Component({
  selector: "ts-report-filters",
  templateUrl: "./report-filters.component.html",
  styleUrls: ["./report-filters.component.scss"],
})
export class ReportFiltersComponent implements OnChanges {
  @Input() items: any[] = [];
  @Input() filter: IReportFilter;
  @Output() applyFilters = new EventEmitter<IReportFilter>();

  public filterControl = new FormControl();
  public filterOptions: any[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items || changes.selectedValues) {
      this.updateFilterOptions();
      const selectedIds = this.filter.selectedOptions.map((opt) => opt.id);
      this.filterControl.setValue(selectedIds);
    }
  }

  // Handle selection changes
  onSelectionChange() {
    const selectedIds = this.filterControl.value || [];
    const updatedSelectedOptions = this.filterOptions.filter((option) =>
      selectedIds.includes(option.id)
    );
    const updatedFilter: IReportFilter = {
      ...this.filter,
      selectedOptions: updatedSelectedOptions,
    };
    this.applyFilters.emit(updatedFilter);
  }

  // Toggle the selection of all options (for the "All" option)
  toggleAllOptions() {
    if (this.isAllSelected()) {
      this.filterControl.setValue([]); // Deselect all options
    } else {
      const allIds = this.filterOptions.map((option) => option.id);
      this.filterControl.setValue(allIds); // Select all options
    }
    this.onSelectionChange(); // Emit the updated filter
  }

  // Check if all options are selected
  isAllSelected(): boolean {
    const selectedIds = this.filterControl.value || [];
    const allIds = this.filterOptions.map((option) => option.id);
    return selectedIds.length === allIds.length;
  }

  isPartialSelected(): boolean {
    const selectedIds = this.filterControl.value || [];
    const allIds = this.filterOptions.map((option) => option.id);
    return selectedIds.length > 0 && selectedIds.length < allIds.length;
  }

  private updateFilterOptions() {
    this.filterOptions = this.items.map((item) => ({
      id: item.id,
      name: item.name,
      type: item.type,
    }));

    const selectedIds = this.filterControl.value || [];
    this.filterControl.setValue(selectedIds);
  }
}
