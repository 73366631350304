import type { ROI } from "../../state";

type Coords = Pick<ROI, "x" | "y" | "w" | "h">;

export class RoiMapper {
  public static getId(coords: Coords, creatorId: string): string {
    const x = coords.x;
    const y = coords.y;
    const w = coords.w;
    const h = coords.h;

    const coordString = JSON.stringify({ x, y, w, h, creatorId });

    return btoa(coordString);
  }
}
