import { Injectable } from "@angular/core";
import { environment } from "@telespot/shared/environment";

@Injectable({
  providedIn: "root",
})
export class AssetFileService {
  getThumbnailUrl(assetFile) {
    const imageRegex = /.(png|bmp|jpg|jpeg|dcm)$/gim;
    const videoRegex = /.(mp4|mov|avi|mpeg)$/gim;
    const fileName = encodeURI(assetFile.substr(0, assetFile.lastIndexOf(".")));
    return imageRegex.test(assetFile)
      ? `${environment.api.url}/files/pyramids/${fileName}/thumbnail`
      : videoRegex.test(assetFile)
      ? `${environment.api.url}/files/videos/${fileName}/thumbnail`
      : `@app/assets/media/image_placeholder.svg`;
  }
}
