import { EntityState, createEntityAdapter } from "@ngrx/entity";

export interface ISample {
  id: string;
  numImages: number;
  name: string;
  methodTypeId: string;
  active: boolean;
  visible: boolean;
  createdById: string;
  device: string;
  createdAt: Date;
}

export interface IMethodType {
  id: string;
  name: string;
  assetType: "image" | "video";
  active: boolean;
}

export interface IAnalyst {
  id: string;
  type: "user" | "algorithm";
  name: string;
}

export interface ILabel {
  category: string;
  categoryUuid: string;
  value: string;
  includeInCounter: boolean;
  taskId: string;
  pipelineId: string;
  methodTypeId: string;
  uuid: string;
  custom?: boolean;
}

export interface ILabelCount {
  labelId: string;
  count: number;
}

export interface IReportFilter {
  filterKey: string;
  displayKey: string;
  selectedOptions: IFilterOption[];
  methodTypeId: string;
}

export interface IFilterOption {
  name: string;
  id: string;
}

export interface ISampleCountEntity {
  analystId: string;
  sampleId: string;
  labelCount: ILabelCount[];
  totalCount: number;
}

export interface ISampleAnalysisInfo {
  [category: string]: string[];
}

export interface ISampleAnalysis {
  analystId: string;
  sampleId: string;
  info: ISampleAnalysisInfo;
}

export interface ICropInfo {
  labelId: string;
  id: string;
  sampleId: string;
  analystId: string;
}

export interface IFavAssetInfo {
  id: string;
  filename: string;
  sampleId: string;
  analystId: string;
}

export interface ReportsState {
  samples: EntityState<ISample>;
  methodTypes: EntityState<IMethodType>;
  analysts: EntityState<IAnalyst>;
  sampleCounter: EntityState<ISampleCountEntity>;
  labels: EntityState<ILabel>;
  sampleAnalysis: EntityState<ISampleAnalysis>;
  cropInfo: EntityState<ICropInfo>;
  favAssets: EntityState<IFavAssetInfo>;
  reportsFilters: EntityState<IReportFilter>;
  conclusions: string;
  loading: boolean;
}

export const sampleAdapter = createEntityAdapter<ISample>({
  selectId: (s) => s.id,
});
export const methodTypeAdapter = createEntityAdapter<IMethodType>({
  selectId: (m) => m.id,
});
export const analystAdapter = createEntityAdapter<IAnalyst>({
  selectId: (a) => a.id,
});
export const sampleCounterAdapter = createEntityAdapter<ISampleCountEntity>({
  selectId: (sc) => `${sc.analystId}/${sc.sampleId}`,
});
export const labelAdapter = createEntityAdapter<ILabel>({
  selectId: (l) => `${l.uuid}/${l.methodTypeId}`,
});
export const sampleAnalysisAdapter = createEntityAdapter<ISampleAnalysis>({
  selectId: (sa) => `${sa.analystId}/${sa.sampleId}`,
});
export const cropInfoAdapter = createEntityAdapter<ICropInfo>({
  selectId: (c) => c.id,
});
export const favAssetsAdapter = createEntityAdapter<IFavAssetInfo>({
  selectId: (fa) => fa.id,
});
export const reportsFiltersAdapter = createEntityAdapter<IReportFilter>({
  selectId: (f) => `${f.filterKey}/${f.methodTypeId}`,
});

export const initialReportState: ReportsState = {
  samples: sampleAdapter.getInitialState(),
  analysts: analystAdapter.getInitialState(),
  sampleCounter: sampleCounterAdapter.getInitialState(),
  labels: labelAdapter.getInitialState(),
  sampleAnalysis: sampleAnalysisAdapter.getInitialState(),
  methodTypes: methodTypeAdapter.getInitialState(),
  cropInfo: cropInfoAdapter.getInitialState(),
  favAssets: favAssetsAdapter.getInitialState(),
  reportsFilters: reportsFiltersAdapter.getInitialState(),
  conclusions: null,
  loading: false,
};
