import { Label } from "@telespot/sdk";

import { IStep } from "../../state";
import { StepTask } from "@telespot/domain";

export class ReportMapper {
  public static getLabelUuidsFromSteps(steps: IStep[]) {
    const uuidSet = new Set<string>();

    steps.forEach((step) => {
      step.params.forEach((category) => {
        uuidSet.add(category.category);
        category.options.forEach((uuid) => {
          uuidSet.add(uuid);
        });
      });
    });
    return Array.from(uuidSet);
  }

  public static toStateLabels(labels: Label[], steps: IStep[]) {
    const labelMapped = [];
    steps.forEach((step) => {
      step.params.forEach((p) => {
        const category = labels.find((l) => l.uuid === p.category);
        if (step.type === StepTask.TEXT) {
          labelMapped.push({
            category: category?.value,
            categoryUuid: category?.uuid,
            value: null,
            includeInCounter: false,
            taskId: step.id,
            pipelineId: step.pipelineId,
            methodTypeId: step.methodTypeId,
            uuid: category?.uuid,
            custom: false,
          });
        } else {
          labelMapped.push(
            ...(p?.options || []).map((o) => {
              const value = labels.find((l) => l.uuid === o)?.value;
              return {
                category: category?.value,
                categoryUuid: category?.uuid,
                value,
                includeInCounter: this.stepForTagging(step),
                taskId: step.id,
                pipelineId: step.pipelineId,
                methodTypeId: step.methodTypeId,
                uuid: o,
                custom: false,
              };
            })
          );
        }
      });
    });

    return labelMapped;
  }

  public static stepForTagging(step: IStep) {
    return (
      step.type === StepTask.POSITION || step.type === StepTask.ROIDETECTION
    );
  }
}
