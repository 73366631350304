import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { ReportsEffects } from "./state/reports.effects";
import { StoreModule } from "@ngrx/store";
import { REPORTS_FEATURE_KEY, reportsReducer } from "./state/reports.reducer";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(REPORTS_FEATURE_KEY, reportsReducer),
    EffectsModule.forFeature([ReportsEffects]),
  ],
})
export class TelespotReportsDataAccessModule {}
