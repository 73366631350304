<div class="color-wrapper">
  <ts-color-palette
    [hue]="hue"
    [color]="color"
    (colorChange)="color = $event; change($event)"
  ></ts-color-palette>
  <ts-color-slider
    [hue]="hue"
    (color)="hue = $event"
    style="margin-left: 16px"
  ></ts-color-slider>
</div>
<label for="color-input" class="floating-label">
  {{ "label.rgb_color_input" | translate }}
</label>
<div class="input-wrapper">
  <textarea
    class="styled-textarea"
    [value]="getRgbValue()"
    (input)="onColorCodeChange($event.target.value)"
  ></textarea>
</div>
