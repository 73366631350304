import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

const COLOR_PICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ColorPickerComponent),
  multi: true,
};

@Component({
  selector: "ts-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
  providers: [COLOR_PICKER_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent implements ControlValueAccessor {
  public hue: string;
  public color: string;

  onChange: (color: string) => void;

  writeValue(color: string): void {
    this.color = this.rgbToRgba(color);
    this.hue = this.rgbToHsl(color);
  }

  registerOnChange(fn: (color: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    return;
  }

  change(color: string): void {
    this.onChange(color);
  }

  onColorCodeChange(value: string) {
    const isValid = this.validateColor(value);

    if (isValid) {
      this.color = value;
      const hslHue = this.rgbToHsl(value);
      if (hslHue) {
        this.hue = hslHue;
      }

      this.change(this.rgbToRgba(this.color));
    }
  }

  validateColor(colorCode: string): boolean {
    const rgbPattern =
      /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;

    const match = rgbPattern.test(colorCode);
    return match;
  }

  private extractRgbValues(color: string): [number, number, number] | null {
    if (!color) return null;

    const rgbRegex =
      /^rgba?\(\s*(\d+),\s*(\d+),\s*(\d+)(?:,\s*(0|1|0?\.\d+))?\s*\)$/;
    const match = color.match(rgbRegex);

    if (match) {
      const r = parseInt(match[1], 10);
      const g = parseInt(match[2], 10);
      const b = parseInt(match[3], 10);
      return [r, g, b];
    }

    return null;
  }

  rgbToRgba(rgb: string): string {
    const rgbValues = this.extractRgbValues(rgb);

    if (rgbValues) {
      const [r, g, b] = rgbValues;
      return `rgba(${r}, ${g}, ${b}, 1)`;
    }

    return rgb;
  }

  getRgbValue(): string {
    const rgbValues = this.extractRgbValues(this.color);

    if (rgbValues) {
      const [r, g, b] = rgbValues;
      return `rgb(${r}, ${g}, ${b})`;
    }

    return this.color;
  }

  rgbToHsl(rgb: string): string | null {
    const rgbRegex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
    const match = rgb.match(rgbRegex);

    if (!match) {
      return null; // Invalid RGBA format
    }

    const [_, r, g, b] = match.map(Number);

    // Normalize RGB values
    const rNorm = r / 255;
    const gNorm = g / 255;
    const bNorm = b / 255;

    const max = Math.max(rNorm, gNorm, bNorm);
    const min = Math.min(rNorm, gNorm, bNorm);
    const delta = max - min;

    let h = 0;

    if (delta === 0) {
      h = 0; // No hue for grayscale colors
    } else if (max === rNorm) {
      h = ((gNorm - bNorm) / delta + (gNorm < bNorm ? 6 : 0)) * 60;
    } else if (max === gNorm) {
      h = ((bNorm - rNorm) / delta + 2) * 60;
    } else if (max === bNorm) {
      h = ((rNorm - gNorm) / delta + 4) * 60;
    }

    return `hsl(${Math.round(h)}, 100%, 50%)`; // Assuming full saturation and lightness
  }
}
