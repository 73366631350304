import {
  REPORTS_FEATURE_KEY,
  getAnalysts,
  getCropInfo,
  getFavAssets,
  getFilters,
  getLabels,
  getMethodTypes,
  getSampleAnalyses,
  getSampleCounters,
  getSamples,
  mapSampleAnalysisInfo,
} from "./reports.reducer";
import { ReportsState } from "./reports.state";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const selectReportsFeature =
  createFeatureSelector<ReportsState>(REPORTS_FEATURE_KEY);

export const getSamplesForMethodType = (methodTypeId: string) =>
  createSelector(selectReportsFeature, (state) =>
    getSamples(state).filter((sample) => sample.methodTypeId === methodTypeId)
  );

export const getSamplesInfo = createSelector(selectReportsFeature, (state) =>
  getSamples(state).map((s) => {
    const methodTypeName = getMethodTypes(state).find(
      (m) => m.id === s.methodTypeId
    )?.name;
    return { ...s, methodTypeName };
  })
);

export const selectAnalysts = createSelector(selectReportsFeature, getAnalysts);

export const getUserAnalysts = createSelector(selectReportsFeature, (state) =>
  getAnalysts(state).filter((an) => an.type === "user")
);

export const getAIAnalysts = createSelector(selectReportsFeature, (state) =>
  getAnalysts(state).filter((an) => an.type === "algorithm")
);

export const selectCropInfo = createSelector(selectReportsFeature, (state) =>
  getCropInfo(state)
);

export const getPipelinesForSample = (sampleId: string) =>
  createSelector(selectReportsFeature, (state) => {
    const methodTypeId = getSamples(state).find(
      (sample) => sample.id === sampleId
    )?.methodTypeId;
    const uniquePipelineIds = new Set<string>();
    getLabels(state).forEach((l) => {
      if (l.methodTypeId === methodTypeId) {
        const pipelineId = l.pipelineId;
        uniquePipelineIds.add(pipelineId);
      }
    });
    return Array.from(uniquePipelineIds);
  });

export const getActiveSample = createSelector(selectReportsFeature, (state) =>
  getSamples(state).find((sample) => sample.active)
);

export const getAllMethodTypes = createSelector(selectReportsFeature, (state) =>
  getMethodTypes(state)
);
export const getAllSamples = createSelector(selectReportsFeature, (state) =>
  getSamples(state)
);

export const selectSampleAnalyses = createSelector(
  selectReportsFeature,
  (state) => getSampleAnalyses(state)
);

export const selectSampleCounters = createSelector(
  selectReportsFeature,
  (state) => getSampleCounters(state)
);

export const selectFavAssets = createSelector(selectReportsFeature, (state) =>
  getFavAssets(state)
);

export const selectAllLabels = createSelector(selectReportsFeature, (state) =>
  getLabels(state)
);

export const getAllFilters = createSelector(selectReportsFeature, (state) =>
  getFilters(state)
);

export const activeMethodType = createSelector(
  getAllMethodTypes,
  (methodTypes) => methodTypes.find((m) => m.active)
);

export const activeFilters = createSelector(
  getAllFilters,
  activeMethodType,
  (filters, activeMethodType) =>
    filters.filter((f) => f.methodTypeId === activeMethodType?.id)
);

export const labelsFromActiveMethodType = createSelector(
  selectAllLabels,
  activeMethodType,
  (labels, activeMethodType) =>
    labels.filter((l) => l.methodTypeId === activeMethodType?.id)
);

export const activeAnalystFilter = createSelector(activeFilters, (filters) =>
  filters.find((f) => f.filterKey === "analyst")
);
export const activeLabelFilter = createSelector(activeFilters, (filters) =>
  filters.find((f) => f.filterKey === "label")
);

export const activeAnalysts = createSelector(
  selectAnalysts,
  activeAnalystFilter,
  (analysts, filter) => {
    const options = (filter?.selectedOptions || []).map((o) => o.id);
    return analysts.filter((an) => options.includes(an.id));
  }
);

export const activeLabelOptions = createSelector(
  labelsFromActiveMethodType,
  (labels) => {
    return labels.reduce((acc, l) => {
      const newOption = {
        id: l.includeInCounter ? l.uuid : l.categoryUuid,
        name: l.includeInCounter ? l.value : l.category,
        type: "label",
      };
      const exists = acc.find((o) => o.id === newOption.id);
      if (!exists) acc.push(newOption);
      return acc;
    }, []);
  }
);

export const activeCounterLabels = createSelector(
  labelsFromActiveMethodType,
  activeLabelFilter,
  (activeLabels, filter) => {
    const options = (filter?.selectedOptions || []).map((o) => o.id);
    return activeLabels
      .filter((l) => l.includeInCounter)
      .filter((l) => options.includes(l.uuid));
  }
);

export const visibleSamples = createSelector(getAllSamples, (allSamples) => {
  return allSamples.filter((s) => s.visible);
});

export const visibleSampleIds = createSelector(
  visibleSamples,
  (visibleSamples) => {
    return visibleSamples.map((s) => s.id);
  }
);

export const getSampleAnalysesBySampleId = (sampleId: string) =>
  createSelector(
    selectSampleAnalyses,
    activeAnalysts,
    activeLabelFilter,
    labelsFromActiveMethodType,
    visibleSampleIds,
    (sampleAnalyses, activeAnalysts, filter, labels, visibleSamples) =>
      sampleAnalyses
        .filter((an) => visibleSamples.includes(an.sampleId))
        .filter((an) => an.sampleId === sampleId)
        .filter((an) =>
          activeAnalysts.map((an) => an.id).includes(an.analystId)
        )
        .map((an) => {
          const analystName = activeAnalysts.find(
            (analyst) => analyst.id === an.analystId
          )?.name;

          const activeLabelIds = filter.selectedOptions.map((l) => l.id);

          const infoFiltered = Object.keys(an.info).reduce((acc, id) => {
            if (activeLabelIds.includes(id)) {
              acc[id] = an.info[id];
            }
            return acc;
          }, {});

          return {
            analystId: an.analystId,
            analystName,
            sampleId: an.sampleId,
            info: mapSampleAnalysisInfo(infoFiltered, labels),
          };
        })
  );

export const getSampleCountersBySampleId = (sampleId: string) =>
  createSelector(
    selectSampleCounters,
    activeAnalysts,
    activeCounterLabels,
    visibleSampleIds,
    (sampleCounters, activeAnalysts, allLabels, visibleSamples) =>
      sampleCounters
        .filter((an) => visibleSamples.includes(an.sampleId))
        .filter((an) => an.sampleId === sampleId)
        .filter((an) =>
          activeAnalysts.map((an) => an.id).includes(an.analystId)
        )
        .map((sc) => {
          const analyst = activeAnalysts.find((an) => an.id === sc.analystId);
          return {
            ...sc,
            analystName: analyst?.name,
            analystType: analyst?.type,
            labelCount: allLabels.reduce((acc, l) => {
              const labelFound = sc.labelCount.find(
                (label) => label.labelId === l.uuid
              );
              const categoryCreatedIdx = acc.findIndex(
                (lc) => lc.category === l.category
              );

              if (categoryCreatedIdx !== -1) {
                acc[categoryCreatedIdx] = {
                  ...acc[categoryCreatedIdx],
                  total: acc[categoryCreatedIdx].total + labelFound?.count || 0,
                  labels: [
                    ...acc[categoryCreatedIdx].labels,
                    {
                      labelId: l.uuid,
                      name: l.value,
                      count: labelFound?.count || 0,
                    },
                  ],
                };
              } else {
                acc.push({
                  category: l.category,
                  categoryId: l.categoryUuid,
                  total: labelFound?.count || 0,
                  labels: [
                    {
                      labelId: l.uuid,
                      name: l.value,
                      count: labelFound?.count || 0,
                    },
                  ],
                });
              }

              return acc;
            }, []),
          };
        })
  );

export const getLabelsByMethodTypeId = (methodTypeId: string) =>
  createSelector(selectAllLabels, (labels) =>
    labels.filter((l) => l.methodTypeId === methodTypeId)
  );

export const getMethodTypeFromSample = (sampleId: string) =>
  createSelector(
    getAllSamples,
    (samples) => samples.find((s) => s.id === sampleId)?.methodTypeId
  );

export const getCropsBySampleId = (sampleId: string) =>
  createSelector(
    selectCropInfo,
    activeCounterLabels,
    visibleSampleIds,
    (cropInfo, activeLabels, visibleSamples) => {
      const activeLabelIds = activeLabels.map((l) => l.uuid);
      return cropInfo
        .filter((an) => visibleSamples.includes(an.sampleId))
        .filter((info) => info.sampleId === sampleId)
        .filter((info) => activeLabelIds.includes(info.labelId))
        .reduce((acc, info) => {
          const cropInfoIdx = acc.findIndex(
            (item) => item.labelId === info.labelId
          );
          const labelValue = activeLabels.find(
            (l) => l.uuid === info.labelId
          )?.value;
          if (cropInfoIdx !== -1) {
            acc[cropInfoIdx] = {
              ...acc[cropInfoIdx],
              cropFileNames: [...acc[cropInfoIdx].cropFileNames, info.id],
            };
          } else {
            acc.push({
              labelId: info.labelId,
              labelValue,
              analystId: info.analystId,
              sampleId: info.sampleId,
              cropFileNames: [info.id],
            });
          }
          return acc;
        }, []);
    }
  );

export const getFiltersByMethodTypeId = (methodTypeId: string) =>
  createSelector(getAllFilters, (filters) =>
    filters.filter((l) => l.methodTypeId === methodTypeId)
  );

export const caseConclusions = createSelector(
  selectReportsFeature,
  (state) => state.conclusions
);

export const numSamplesByMethodType = (methodTypeId: string) =>
  createSelector(
    selectReportsFeature,
    (state) =>
      getSamples(state).filter((s) => s.methodTypeId === methodTypeId)?.length
  );

export const loading = createSelector(
  selectReportsFeature,
  (state) => state.loading
);

export const getFavAssetsFromSample = (sampleId: string) =>
  createSelector(
    selectFavAssets,
    visibleSampleIds,
    (favAssets, visibleSamples) =>
      favAssets
        .filter((an) => visibleSamples.includes(an.sampleId))
        .filter((f) => f.sampleId === sampleId)
  );

export const getAnalystFromCrops = (sampleId: string) =>
  createSelector(
    selectCropInfo,
    activeAnalysts,
    visibleSampleIds,
    (cropInfo, activeAnalysts, visibleSampleIds) => {
      const crop = cropInfo.find(
        (i) => visibleSampleIds.includes(i.sampleId) && i.sampleId === sampleId
      );

      return crop
        ? activeAnalysts.find((analyst) => analyst.id === crop.analystId)?.name
        : undefined;
    }
  );
