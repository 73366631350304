import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import {
  IAnalyst,
  ICropInfo,
  IFavAssetInfo,
  IReportFilter,
  ISample,
  ISampleAnalysis,
  ISampleCountEntity,
} from "@telespot/reports/data-access";
import { AssetFileService, MosaicService } from "@telespot/web-core";

@Component({
  selector: "ts-results-table",
  templateUrl: "./results-table.component.html",
  styleUrls: ["./results-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsTableComponent {
  /** PUBLIC VARIABLES */
  @Input() sampleAnalyses: ISampleAnalysis[];
  @Input() sampleCounter: ISampleCountEntity[];
  @Input() sample: ISample;
  @Input() cropInfo: ICropInfo[];
  @Input() filters: IReportFilter[];
  @Input() analysts: IAnalyst[];
  @Input() labels: any[];
  @Input() favAssets: IFavAssetInfo[];
  @Input() preview = false;
  @Input() cropsAnalyst: string;
  @Output() updatedFilter = new EventEmitter<IReportFilter>();

  constructor(
    private mosaicService: MosaicService,
    private fileService: AssetFileService
  ) {}

  /* UI Handlers Methods */

  onApplyFilters(updatedFilter: IReportFilter) {
    this.updatedFilter.emit(updatedFilter);
  }

  public getCrop(roiId: string) {
    const url = localStorage.getItem(`crop_${roiId}`);
    if (!url) {
      return null;
    }
    const sanitizedUrl = this.mosaicService.getCropSanitizedUrl(url);
    return sanitizedUrl;
  }

  public getThumbnailUrl(assetFile) {
    return this.fileService.getThumbnailUrl(assetFile);
  }

  getKeys(data) {
    return Object.keys(data);
  }

  getResultValue(data, key: string): string[] {
    const value = data[key];
    return value ? value : ["-"];
  }
  getLabels(results) {
    return results[0]?.labelCount || [];
  }

  getLabelTotal(result, labelName: string) {
    const label = result.labelCount.find((l) => l.category === labelName);
    return label ? label.total : 0;
  }

  getLabelPerc(result, labelName: string) {
    const label = result.labelCount.find((l) => l.category === labelName);
    const value = label
      ? Number((label.total / result.totalCount).toFixed(2))
      : 0;
    return isNaN(value) ? 0 : value;
  }

  getSublabelTotal(result, labelName: string, sublabelName: string) {
    const label = result.labelCount.find((l) => l.category === labelName);
    const sublabel = label?.labels.find((s) => s.name === sublabelName);
    return sublabel ? sublabel.count : 0;
  }

  getSublabelPerc(result, labelName: string, sublabelName: string) {
    const label = result.labelCount.find((l) => l.category === labelName);
    const sublabel = label?.labels.find((s) => s.name === sublabelName);
    const value = sublabel
      ? Number((sublabel.total / label.total).toFixed(2))
      : 0;
    return isNaN(value) ? 0 : value;
  }

  getTotalPerc(total: number) {
    return total === 0 ? total : 1.0;
  }
}
