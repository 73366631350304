<div class="main-container" *ngIf="case">
  <!-- BREADCRUMBS & BUTTONS -->
  <div class="title">
    <span
      class="breadcrumbs flex-row flex-align-center"
      style="justify-content: flex-start; max-width: 100%"
    >
      <a routerLink="/workspaces">
        {{ "title.reports" | translate }}
      </a>
      <a
        [routerLink]="['/workspaces', case.workspace.id, 'cases']"
        [queryParams]="{ page: 0, pageSize: 20 }"
      >
        {{ case.workspace.name }}
      </a>
      <a>{{ case.name }}</a>
    </span>
    <div class="actions">
      <button
        class="btn btn-primary"
        (click)="reopenCase()"
        data-cy="button-reopen-case"
      >
        <i class="ri-folder-5-line"></i>
        {{ "button.reopen_case" | translate }}
      </button>
    </div>
  </div>
  <!-- METHOD TYPES REPORTS -->
  <ng-container *ngIf="reportService.samplesInfo$ | async as samplesInfo">
    <div class="card mt-3">
      <ts-report-panel
        [samples]="samplesInfo"
        (changesApplied)="loadSamplesInfo($event)"
        [loading]="(reportService.loading$ | async) === true"
        (export)="export()"
      ></ts-report-panel>
    </div>
  </ng-container>
  <!-- CASE DATA INFO -->
  <div class="flex-row flex-wrap">
    <ts-case-data
      class="card flex-grow-1 case-data-container"
      [case]="case"
    ></ts-case-data>
    <ts-case-info class="card flex-grow-1"></ts-case-info>
  </div>

  <ng-container *ngIf="reportService.activeSample$ | async">
    <ng-container
      *ngIf="{
        samples: reportService.visibleSamples$ | async,
        methodTypes: reportService.methodTypes$ | async,
        activeMethodType: reportService.activeMethodType$ | async,
        activeSample: reportService.activeSample$ | async
      } as state"
    >
      <mat-accordion class="spotlab m-2">
        <mat-expansion-panel
          *ngFor="let methodType of state.methodTypes; let i = index"
          [expanded]="i === 0 && state.methodTypes.length === 1"
          class="custom-mat-expansion-panel"
          (opened)="onPanelOpened(methodType.id, state.activeMethodType?.id)"
        >
          <mat-expansion-panel-header class="flex-row align-items-center">
            <mat-panel-title class="flex-row align-items-center">
              <i
                [ngClass]="{
                  'ri-film-line': methodType.type === 'video',
                  'ri-image-line': methodType.type === 'image'
                }"
              ></i>
              {{
                ("title.samples" | translate) + " [" + methodType.name + "]"
                  | uppercase
              }}
            </mat-panel-title>
            <ng-container
              *ngIf="
                reportService.getNumSamples(methodType.id) | async as numSamples
              "
            >
              <mat-panel-description>
                {{ numSamples }}
                {{ "core.samples" | translate }}
              </mat-panel-description>
            </ng-container>
          </mat-expansion-panel-header>
          <mat-tab-group
            mat-stretch-tabs
            [@.disabled]="true"
            (selectedTabChange)="
              onSampleTabChange($event, state.activeSample?.id, state.samples)
            "
          >
            <mat-tab
              *ngFor="let sample of state.samples"
              label="{{ sample.name | uppercase }}"
            >
              <ng-template matTabContent>
                <ng-container
                  *ngIf="
                    (reportService.loading$ | async) === false;
                    else loadingResults
                  "
                >
                  <ts-results-table
                    [sampleAnalyses]="
                      reportService.getSampleAnalysesBySampleId(sample.id)
                        | async
                    "
                    [sampleCounter]="
                      reportService.getSampleCountersBySampleId(sample.id)
                        | async
                    "
                    [sample]="sample"
                    [cropInfo]="
                      reportService.getCropInfoBySampleId(sample.id) | async
                    "
                    [filters]="reportService.activeFilters$ | async"
                    [analysts]="reportService.analysts$ | async"
                    [labels]="reportService.labelOptions$ | async"
                    [favAssets]="
                      reportService.getFavAssetsFromSample(sample.id) | async
                    "
                    [cropsAnalyst]="
                      reportService.getAnalystFromCrops(sample.id) | async
                    "
                    (updatedFilter)="reportService.updateFilter($event)"
                  >
                  </ts-results-table>
                </ng-container>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </ng-container>

  <div class="card mt-3">
    <div class="card-header">
      <h3 class="bold">{{ "title.conclusions" | translate | uppercase }}</h3>
    </div>
    <div class="card-body">
      <textarea
        class="conclusions-input"
        placeholder="{{ 'label.conclusions_placeholder' | translate }}"
        rows="5"
        [value]="reportService.conclusions$ | async"
        (input)="reportService.onConclusionsChange($event.target.value)"
      ></textarea>
    </div>
  </div>

  <!-- LOADING PROGRESS BAR -->
  <ng-template #loadingResults>
    <div class="card" style="margin: 10px 0px">
      <div class="card-body">
        {{ "info.loading_data" | translate }}
        <mat-progress-bar mode="query"></mat-progress-bar>
      </div>
    </div>
  </ng-template>
</div>
