import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CaseDataModule, CaseInfoModule } from "@telespot/analysis-refactor/ui";
import { PreprocessFilePipeModule } from "@telespot/shared/util";

import { ReportComponent } from "./components/report/report.component";
import { ResultsTableComponent } from "./components/results-table/results-table.component";
import { ReportsRoutingModule } from "./reports-routing.module";
import { MatTabsModule } from "@angular/material/tabs";
import { ReportFiltersComponent } from "./components/report-filters/report-filters.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { TelespotReportsDataAccessModule } from "@telespot/reports/data-access";
import { ReportPanelComponent } from "./components/report-panel/report-panel.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { PrintableReportComponent } from "./components/printable-report/printable-report.component";

@NgModule({
  declarations: [
    ReportComponent,
    PrintableReportComponent,
    ResultsTableComponent,
    ReportFiltersComponent,
    ReportPanelComponent,
  ],
  imports: [
    CommonModule,
    TelespotReportsDataAccessModule,
    RouterModule,
    TranslateModule,
    MatProgressBarModule,
    PreprocessFilePipeModule,
    MatCardModule,
    MatExpansionModule,
    ReportsRoutingModule,
    CaseDataModule,
    CaseInfoModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
})
export class TelespotReportsFeatureModule {}
