import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";

import {
  CaseAnalysisService,
  setCurrWorkspace,
} from "@telespot/analysis-refactor/data-access";
import { ISample, ReportGeneratorService } from "@telespot/reports/data-access";
import { Case, CloudFunctions, StateName } from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { AuthService } from "@telespot/web-core";
import { Subject } from "rxjs";

@Component({
  selector: "ts-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
  providers: [CaseAnalysisService, ReportGeneratorService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportComponent implements OnInit, OnDestroy {
  /** PRIVATE VARIABLES */
  private _destroy$ = new Subject<void>();

  /** PUBLIC VARIABLES */
  public case: Case;

  constructor(
    public reportService: ReportGeneratorService,
    private _caseAnalysisService: CaseAnalysisService,
    private _logger: LoggerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _authService: AuthService,
    private _store: Store
  ) {}

  /* Component Lifecycle Methods */

  ngOnInit() {
    this.reportService.clearReportState();
    this.case = this.route.snapshot.data["case"];
    this._caseAnalysisService.setCase(this.case);
    this._store.dispatch(
      setCurrWorkspace({
        id: this.case.workspace.id,
      })
    );

    this.reportService.initializeReport(this.case.id);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /* UI Handlers Methods */

  public onPanelOpened(methodTypeId, activeMethodTypeId): void {
    if (methodTypeId === activeMethodTypeId) return;
    this.reportService.setActiveMethodType(methodTypeId);
  }

  public onSampleTabChange(
    event: MatTabChangeEvent,
    activeSampleId: string,
    samples: ISample[]
  ): void {
    if (samples[event.index].id === activeSampleId) return;
    this.reportService.setActiveSample(samples[event.index].id);
  }

  public loadSamplesInfo(sampleIds: string[]) {
    this.reportService.loadSamplesInfo(sampleIds);
  }

  public async reopenCase() {
    try {
      await CloudFunctions.SetState(
        this.case,
        StateName.rewiew,
        this._authService.currentUser
      );
    } catch (error) {
      this._logger.error(`Error changing case state: ${error.message}`);
    }
    this.router.navigate(["/workspaces", this.case.workspace.id, "cases"], {
      queryParams: { page: 0, pageSize: 20 },
    });
  }

  public export() {
    this.router.navigate(["print"], { relativeTo: this.route });
  }
}
